import React, { ChangeEvent, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { HorizontalScrollView } from '../../../components/HorizontalScrollView';
import { KeywordButton } from '../../../components/KeywordButton';
import { ProductCard } from '../../../components/ProductCard';
import { Select } from '../../../components/Select';
import { TopBar } from '../../../components/TopBar';
import { useCategory } from '../../../queries/categories';
import { useHistory, useParams } from 'react-router-dom';
import { IdParams } from '../../../types/router';
import { useProducts } from '../../../queries/products';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import qs from 'qs';

// TODO
//  - 상품 없을 경우 뷰
//  - useProducts -> type 처리 ?
//  - 총 몇권 -> 총 개수 어케 구분할지
export const CategoryPage = () => {
  const { id } = useParams<IdParams>();
  const { push, replace, location } = useHistory();
  const queryParams = qs.parse(location.search.replace('?', ''));
  const sortBy = (queryParams.sortBy as string) ?? 'rank';
  const subCategoryId = queryParams.sub as string;
  const { category } = useCategory(+id);
  const { products, totalItemCount, fetchNextPage, hasNextPage } = useProducts(
    +subCategoryId || +id,
    sortBy
  );

  const changeSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
    replace({
      search: qs.stringify({
        ...queryParams,
        sortBy: e.target.value,
      }),
    });
  };

  const handleSelectSubcategory = useCallback(
    (categoryId = null) => {
      if (!categoryId) {
        delete queryParams.sub;
      }
      push({
        search: qs.stringify({
          ...queryParams,
          ...(categoryId && { sub: categoryId }),
        }),
      });
    },
    [push, queryParams]
  );

  return (
    <div className=" lg:mx-auto lg:min-w-[50vw] lg:max-w-screen-md xl:min-w-[89vw] 2xl:min-w-[50vw]">
      <TopBar title={category?.name} />
      <div className="px-5 md:px-0">
        {/* 세부카테고리(태그)가 있을시에만 태그 활성화 (없을시 히든처리) */}
        {category?.categories && (
          <>
            <Swiper
              className="mySwiper auto hidden md:block"
              slidesPerView={'auto'}
              slidesPerGroup={category.categories.length}
              spaceBetween={12}
              freeMode={true}
              modules={[FreeMode]}
            >
              <SwiperSlide>
                <KeywordButton
                  onClick={() => handleSelectSubcategory()}
                  selected={!subCategoryId}
                  title={`전체`}
                />
              </SwiperSlide>
              {category.categories.map((subcategory) => (
                <SwiperSlide key={subcategory.id}>
                  <KeywordButton
                    onClick={() => handleSelectSubcategory(subcategory.id)}
                    selected={subcategory.id === +subCategoryId}
                    key={subcategory.id}
                    title={subcategory.name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <HorizontalScrollView className="md:hidden">
              <KeywordButton
                onClick={() => handleSelectSubcategory()}
                selected={!subCategoryId}
                title={`전체`}
              />

              {category.categories.map((subcategory) => (
                <KeywordButton
                  onClick={() => handleSelectSubcategory(subcategory.id)}
                  selected={subcategory.id === +subCategoryId}
                  key={subcategory.id}
                  title={subcategory.name}
                />
              ))}
            </HorizontalScrollView>
          </>
        )}
        <div className="flex items-center justify-between">
          <Select
            className="border-0"
            onChange={changeSortBy}
            defaultValue={sortBy}
          >
            <option value="rank">추천순</option>
            <option value="recent">최신순</option>
            <option value="popular">인기순</option>
          </Select>
          <span className="text-12 text-gray-500">
            총 {totalItemCount?.toLocaleString()}{' '}
            {category?.type === 'BOOK' ? '권' : '개'}
          </span>
        </div>

        <InfiniteScroll
          dataLength={products?.length ?? 0}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<p>로딩중</p>}
          className="grid grid-cols-2 gap-x-5 gap-y-8 md:grid-cols-6"
        >
          {products?.map((p) => (
            <ProductCard
              key={p.id}
              to={`/product/${p.id}`}
              product={p}
              showRanking={sortBy === 'rank'}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};
