import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Section, sectionComponent } from '../../components/Section';
import { useArcNShop } from '../../queries/arcNShop';
import { useMainBanners } from '../../queries/mainBanners';
import { goToUrl } from '../home/utils';
import { useSections } from '../../queries/sections';
import { HorizontalScrollView } from '../../components/HorizontalScrollView';
import { useCategories } from '../../queries/categories';
import { TabType } from '../../enums';
import { Category } from '../../types/categories';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const ArcNShop = () => {
  const { push } = useHistory();
  const { mainBanners } = useMainBanners('SHOP');
  const { sections } = useSections('LIFE');
  const { categories } = useCategories(TabType.LIFE);

  const [isMore, setIsMore] = React.useState(false);

  // TODO: 카테고리 클릭 이벤트
  const onClickCategory = (category: Category) => {
    push(`/categories/${category.id}`);
  };

  return (
    <>
      <Section>
        {mainBanners.length > 0 && (
          <Swiper
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            loop
            id="white_bullet"
            modules={[Pagination]}
            className="mySwiper"
          >
            {mainBanners.map((banner) => (
              <SwiperSlide key={banner.id}>
                <div
                  onClick={() => goToUrl(banner.url)}
                  style={{
                    backgroundImage: `url(${
                      isMobile
                        ? banner.mobileBannerImageUrl
                        : banner.pcBannerImageUrl
                    })`,
                  }}
                  className={`relative aspect-[4/5]  bg-cover bg-center bg-no-repeat md:aspect-[3/1]`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Section>
      <Section className="mt-14 w-full space-y-4 px-5 md:mx-auto md:max-w-screen-xl xl:px-0">
        <div className={'flex items-center justify-between'}>
          <h6 className="font-bold">상품 카테고리</h6>
          <span className={'cursor-pointer'} onClick={() => setIsMore((prev) => !prev)}>
            더보기
          </span>
        </div>
        <CategoryView
          categories={categories}
          onClick={onClickCategory}
          isMore={isMore}
        />
      </Section>

      {sections.map((section) => (
        <div
          key={section.id}
          className="w-full px-5 md:mx-auto md:max-w-screen-xl xl:px-0"
        >
          {sectionComponent[section.type](section)}
        </div>
      ))}
      {/*<div className="grid grid-cols-2 gap-5 md:mt-10 md:grid-cols-6 md:gap-y-8">*/}
      {/*  {arcNShop?.products?.map((product) => (*/}
      {/*    <ProductCard*/}
      {/*      product={product.product}*/}
      {/*      to={`/product/${product.product.id}`}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</div>*/}
    </>
  );
};

interface CategoryViewProps {
  categories: Category[];
  onClick: (category: Category) => void;
  isMore: boolean;
}

const MAX_DEVICE_WIDTH = 1280;
const CategoryView = ({ categories, onClick, isMore }: CategoryViewProps) => {
  const { width } = useWindowDimensions();

  const isNotOpenCategoryCount = useMemo(() => {
    if (MAX_DEVICE_WIDTH < width) {
      return 13;
    } else {
      return Math.floor((width - 24) / 96);
    }
  }, [width]);

  const categoryArray = isMore
    ? categories
    : categories.slice(0, isNotOpenCategoryCount);

  return (
    <div className="flex flex-wrap gap-4">
      {categoryArray.map((category) => (
        <button
          onClick={() => onClick(category)}
          key={category.id}
          className="wh-20 mb-2 grid snap-start place-content-center rounded-xl bg-brand-3 px-3 text-center font-semibold shadow-3xl"
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};
