import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import useAuth from '../../hooks/useAuth';
import { LoginBody } from '../../types/user';
import { Order } from '../../types/orders';

export const LoginPage = () => {
  const { location } = useHistory<{ isNonMemberCart: boolean | undefined }>();

  const { state } = location;
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginBody>();

  return (
    <>
      <div className="mx-auto w-full max-w-md md:my-12">
        <TopBar title="로그인" />

        <form
          className="flex flex-col space-y-4 pt-6"
          onSubmit={handleSubmit((data) => login(data))}
        >
          <div className={'flex flex-col space-y-4 px-5'}>
            <div className={'flex flex-col space-y-2'}>
              <TextField
                type="email"
                placeholder="이메일을 입력해주세요"
                peerStyle={false}
                helper={errors.email?.message}
                {...register('email', { required: '이메일을 입력해주세요' })}
              />

              <TextField
                type="password"
                placeholder="비밀번호를 입력해주세요"
                peerStyle={false}
                helper={errors.password?.message}
                {...register('password', {
                  required: '비밀번호를 입력해주세요',
                })}
              />
            </div>
            <div className={'flex flex-col gap-1'}>
              <Button
                text="로그인"
                className="filled-gray-900"
                onClick={handleSubmit((data) =>
                  login(data, state?.isNonMemberCart)
                )}
              />
              <Button
                type="button"
                to="/signup"
                text="회원가입"
                className="outlined-gray-900"
              />
            </div>
            <div className="mx-auto text-center text-14 text-gray-500">
              <Link to="/username/find" className="mr-3">
                아이디 찾기
              </Link>{' '}
              |{' '}
              <Link to="/passwords/find" className="mx-3">
                비밀번호 변경
              </Link>
            </div>
          </div>
          <div className="flex flex-col space-y-2 px-5 pt-6">
            {state?.isNonMemberCart && (
              <Button
                type="button"
                to="/cart/pay"
                text="비회원 주문하기"
                className="filled-gray-900"
              />
            )}
            <Button
              type="button"
              to="/non-member"
              text="비회원 주문조회"
              className="outlined-gray-900"
            />
          </div>
        </form>
      </div>
    </>
  );
};
