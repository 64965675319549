import {
  ChangePasswordRequestBody,
  FindEmailRequestBody,
  GetUserIdRequestParams,
  GetUserIdResponse,
  JwtContainer,
  LibroUserInfo,
  LoginBody,
  SignUpBody,
  User,
} from '../types/user';
import base from './base';

interface GetUserExistsRequestParams {
  email?: string;
  phone?: string;
}

const user = {
  getUserExists(params: GetUserExistsRequestParams) {
    return base.get<boolean>('/user/exists', {
      params: {
        ...params,
      },
    });
  },
  getLibroUser(phone: string) {
    return base.get<LibroUserInfo>('/user/libroUser', {
      params: {
        phone,
      },
    });
  },
  postUserFindEmail(body: FindEmailRequestBody) {
    return base.post<string>('/user/findEmail', body);
  },
  getUser() {
    return base.get<User>('/user');
  },
  postUser(body: SignUpBody) {
    return base.post('/user', body);
  },
  patchUser(body: Partial<SignUpBody>) {
    return base.patch('/user', body);
  },
  getUserId(params: GetUserIdRequestParams) {
    return base.get<GetUserIdResponse>('/user/userId', {
      params,
    });
  },
  patchUserPassword(id: number, body: ChangePasswordRequestBody) {
    return base.patch(`/user/password/${id.toString()}`, body);
  },
  getUserSession() {
    return base.get<User>('/user/session');
  },
  postUserSession(body: LoginBody) {
    return base.post<JwtContainer>('/user/session', body);
  },
  putUserSession() {
    return base.put<JwtContainer>('/user/session');
  },
  deleteUser() {
    return base.delete('/user');
  },
};

export default user;
