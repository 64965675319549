import axios, { AxiosError } from 'axios';
import API from '.';
import { tokenState } from '../plugins/ridge';

const config = {
  baseURL: process.env.REACT_APP_API_URL, // TODO: 환경변수로 분리
  withCredentials: true,
};

const base = axios.create(config);

// TODO: 공용 request / response interceptor 추가
base.interceptors.request.use((config) => {
  const token = tokenState.get();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

let currentTryCount = 0;
const MAX_TRY_COUNT = 1;

base.interceptors.response.use(
  async (res: any) => {
    if (res.headers.Authorization) {
      tokenState.set(res.headers.authorization);
    }
    return res;
  },
  async (err: AxiosError) => {
    if (err.response?.status === 401) {
      const prevToken = tokenState.get();
      if (prevToken && currentTryCount < MAX_TRY_COUNT) {
        currentTryCount += 1;
        const {
          data: { token },
        } = await API.putUserSession();
        tokenState.set(token);
      } else if (currentTryCount === MAX_TRY_COUNT) {
        tokenState.set(null);
      }
    }
    return Promise.reject(err);
  }
);

export default base;

export const makeWhereParams = (where: object) => {
  return Object.entries(where).reduce((curr, [key, value]) => {
    return {
      ...curr,
      [`where[${key}]`]: value,
    };
  }, {});
};
