import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { KeywordButton } from '../../components/KeywordButton';
import { useKeywords } from '../../queries/keywords';
import { SectionDto } from '../../types/sections';
import { Header } from './Header';
import { KeywordType } from '../../types/keyword';

interface KeywordSectionProps {
  section: SectionDto;
}

export const KeywordSection: FC<KeywordSectionProps> = ({ section }) => {
  const { push } = useHistory();
  const { keywords } = useKeywords(
    section.position === 'MAIN' ? 'SECTION' : (section.position as KeywordType)
  );
  return (
    <div className="mt-12">
      <Header rowReverse title={section.title} />

      {keywords?.map((keyword) => (
        <KeywordButton
          key={keyword.id}
          onClick={() => push(`/search/${keyword.keyword}`)}
          title={keyword.keyword}
        />
      ))}
    </div>
  );
};
