import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCartContext } from '../../context/cart';
import { modalState } from '../../plugins/ridge';
import {
  useAddProductToCart,
  useRemoveProductToCart,
} from '../../queries/cart';
import { CartItem } from '../../types/cart';
import { displayProductInfo } from '../../utils';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icon';
import { ProductCardPrice } from '../ProductCard';
import { SoldOutOverlay } from '../SoldOutOverlay';
import useAuth from '../../hooks/useAuth';
import {
  useAddProductToNonCart,
  useRemoveProductToNonCart,
} from '../../queries/nonMemberCarts';

interface DesktopCartCardProps {
  item: CartItem;
  remainQuantity?: number;
  isPickup?: boolean;
}

export const DesktopCartCard: FC<DesktopCartCardProps> = ({
  item,
  remainQuantity,
  isPickup = false,
}) => {
  const { authenticated } = useAuth();

  const { product, quantity } = item;
  const { push } = useHistory();
  const { addProductToCart } = useAddProductToCart(false);
  const { addProductToNonCart } = useAddProductToNonCart(false);
  const { removeProductToCart } = useRemoveProductToCart();
  const { removeProductToNonCart } = useRemoveProductToNonCart();
  const { toggleSelect, checkIsSelected } = useCartContext();

  const checkRemoveAll = () => {
    modalState.set({
      title: '장바구니 제거',
      bodyText: '선택하신 상품을 장바구니에서 삭제하시겠습니까?',
      primaryButtonText: '확인',
      primaryClick: () => {
        if (authenticated) {
          removeProductToCart({
            product,
            cartQuantity: quantity,
            branchCode: item.branchCode,
          });
        }
        if (!authenticated) {
          removeProductToNonCart({
            product,
            cartQuantity: quantity,
          });
        }
      },
      secondaryButtonText: '취소',
    });
  };

  const isSoldOut = isPickup
    ? remainQuantity === 0
    : product.isSoldOut || remainQuantity === 0;
  const onToggleCheckbox = () => {
    if (isSoldOut) return;
    toggleSelect(item);
  };

  const goToProduct = () => push(`/product/${product.id}`);

  const addProduct = () => {
    if ((remainQuantity && quantity >= remainQuantity) || isSoldOut) {
      toast.info('재고가 부족합니다.');
      return;
    }

    if (authenticated) {
      addProductToCart({ product, branchCode: item.branchCode });
      return;
    }
    addProductToNonCart({ product });
    return;
  };

  const decreaseProduct = () => {
    if (quantity <= 1) {
      return;
    }
    authenticated
      ? removeProductToCart({
          product,
          branchCode: item.branchCode,
        })
      : removeProductToNonCart({
          product,
        });
  };

  return (
    <div className="flex items-center justify-between px-5">
      <div className="flex flex-1 items-center space-x-6 pr-20">
        <Checkbox onChange={onToggleCheckbox} checked={checkIsSelected(item)} />
        <div className="relative cursor-pointer" onClick={goToProduct}>
          <img
            src={product.images[0]}
            alt=""
            className="h-32 w-20 overflow-hidden rounded-xl bg-brand-3 object-cover"
          />
          {isSoldOut && <SoldOutOverlay />}
          {product.promotionId > 0 && (
            <div className="absolute top-3 left-3 z-10 h-fit w-max rounded-full bg-brand-1 px-3 py-1 text-12 font-semibold text-white">
              이벤트
            </div>
          )}
        </div>
        <p className="flex-1">
          <span
            className="cursor-pointer text-17 font-bold"
            onClick={goToProduct}
          >
            {product.name}{' '}
            {isSoldOut && <span className="text-13 text-red-500">(품절)</span>}
          </span>
          <br />
          <span className="text-14 text-gray-500">
            {displayProductInfo(product)}
          </span>
        </p>
      </div>

      <div className="flex items-center space-x-12">
        <ProductCardPrice product={product} containerClass="text-16" />

        <div className="flex items-center space-x-3 pt-4">
          {product.promotionId ? (
            <div className="w-7"></div>
          ) : (
            <button
              className="wh-7 grid place-content-center rounded-full text-20 font-bold shadow-3xl"
              onClick={decreaseProduct}
            >
              -
            </button>
          )}
          <span className="text-13">{quantity}</span>
          {product.promotionId ? (
            <div className="w-7"></div>
          ) : (
            <button
              className="wh-7 grid place-content-center rounded-full text-14 font-bold shadow-3xl"
              onClick={addProduct}
            >
              +
            </button>
          )}
        </div>

        <button className="mt-5 grid place-content-center">
          <Icon.Trash className="wh-5 text-gray-600" onClick={checkRemoveAll} />
        </button>
      </div>
    </div>
  );
};
