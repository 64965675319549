import React, { FC } from 'react';

interface TabProps {
  selected: boolean;
  onClick: () => void;
  text: string;
}

export const Tab: FC<TabProps> = ({ selected, onClick, text }) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selected
          ? 'border-b-2 border-gray-900 font-bold text-gray-900 md:filled-gray-900'
          : 'md:bg-brand-3 md:text-gray-500'
      } w-full cursor-pointer py-5 text-center text-14`}
    >
      {text}
    </div>
  );
};
