import React, { FC } from 'react';
import { CalculateOrderResponse } from '../../types/orders';
import { CalculateResult } from '../../types/shipment';
import { useAuth } from '../../hooks';

interface TotalAmountProps {
  orderCalculateResult: CalculateOrderResponse;
  isPickUp: boolean;
}

export const TotalAmount: FC<TotalAmountProps> = ({
  orderCalculateResult,
  isPickUp,
}) => {
  const { authenticated } = useAuth();
  return (
    <div className="md:card-2 mt-8 h-auto space-y-3 text-15 md:mt-0 md:min-w-[420px] md:rounded-lg">
      <h6 className="hidden font-bold md:block">결제 금액</h6>

      <div className="flex justify-between">
        <span className="text-gray-500">총 주문금액</span>
        <span className="">
          {(orderCalculateResult.totalProductPrice || 0).toLocaleString()}원
        </span>
      </div>
      <div className="flex justify-between">
        <span className="text-gray-500">총 할인금액</span>
        <span className="">
          {(
            orderCalculateResult.totalDiscountAmount ||
            0 ||
            0
          ).toLocaleString()}
          원
        </span>
      </div>

      {!isPickUp && (
        <div className="flex justify-between pb-4">
          <span className="text-gray-500">배송비</span>
          <span className="">
            {(orderCalculateResult.shippingPrice || 0).toLocaleString()}원
          </span>
        </div>
      )}

      <hr className="border-gray-100" />

      <div className="flex justify-between pt-4 text-17 font-bold">
        <span className="">총 결제금액</span>
        <span className="">
          {(orderCalculateResult.totalPayAmount || 0).toLocaleString()}원
        </span>
      </div>

      <div className="flex justify-between pb-4">
        <div>
          <span className="text-gray-500">적립예정</span>
          <p className="mt-1 text-13 text-gray-400">
            실결제금액 기준 도서 5% / 일반상품 1%
          </p>
          {!authenticated && (
            <p className={'mt-1 text-13 font-bold text-gray-400'}>
              회원가입하고 적립금, 쿠폰 할인 등의 다양한 혜택을 받아보세요
            </p>
          )}
        </div>
        <span className="">{orderCalculateResult.receivablePointAmount}P</span>
      </div>

      {!isPickUp && (
        <p className="border-t border-gray-100 pt-3 text-12 text-gray-500">
          * 일반상품의 경우 각 브랜드에서 직접 배송하여, 배송이 따로 이뤄질 수
          있습니다.
          <br />* 적립금은 배송이 완료된 시점에 적립됩니다.
        </p>
      )}
    </div>
  );
};
