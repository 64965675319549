// TODO: Marketing agreement url 업데이트 필요
const CONST = {
  TERMS_AGREEMENT_URL:
    'https://jelly-dancer-b9e.notion.site/b67c0b6df7f44cd783141ea16a4513e9?pvs=4',
  PRIVACY_AGREEMENT_URL:
    'https://jelly-dancer-b9e.notion.site/83a6d46462ea4a35a0e4f54c9848be5f?pvs=4',
  MARKETING_AGREEMENT_URL:
    'https://jelly-dancer-b9e.notion.site/9df5b25305404351870bbcbc3aec4902',
  YOUTH_SAFE_POLICY:
    'https://jelly-dancer-b9e.notion.site/b3cd305d949440e1a2216a7fb2603ebb?pvs=4',
};

export default CONST;
