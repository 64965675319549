import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../api';
import { tokenState } from '../plugins/ridge';
import { LoginBody } from '../types/user';
import { useQueryClient } from 'react-query';

const useAuth = () => {
  const { push } = useHistory();
  const [token, setToken] = tokenState.use();
  const queryClient = useQueryClient();
  const authenticated = token !== null;
  const login = (data: LoginBody, goToCart = false) => {
    API.postUserSession(data)
      .then(({ data: { token } }) => {
        setToken(token);
        queryClient.invalidateQueries('cart');
        goToCart ? push('/cart') : push('/');
      })
      .catch((error) => {
        if (!axios.isAxiosError(error)) return;
        if (error?.response?.status === 401) {
          toast.error('이메일주소 또는 비밀번호가 틀렸습니다.');
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  const logout = () => {
    tokenState.reset();
    queryClient.clear();
    push('/');
  };
  return { token, authenticated, login, logout };
};

export default useAuth;
