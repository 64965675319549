import React from 'react';
import { useHistory } from 'react-router-dom';
import { HorizontalScrollView } from '../../components/HorizontalScrollView';
import { KeywordButton } from '../../components/KeywordButton';
import { Search } from '../../components/Search';
import { Section } from '../../components/Section';
import { TopBar } from '../../components/TopBar';
import {
  useCategories,
  useRecommendedCategories,
} from '../../queries/categories';
import { useKeywords } from '../../queries/keywords';
import { RecommendedCategory } from '../../types/categories';
import { TabType } from '../../enums';

export const CategoriesPage = () => {
  const { push } = useHistory();
  const { categories } = useCategories(TabType.BOOK);
  const { keywords } = useKeywords('SEARCH');
  const { recommendedCategories } = useRecommendedCategories();
  const onClickRecommendedCategory = (category: RecommendedCategory) => {
    window.open(category.url, '_self');
  };
  const onSearch = (value: string) => {
    if (!value) return;
    push(`/search/${value}`);
  };
  return (
    <>
      <TopBar backButton={true} />
      <div className="space-y-8 p-5 md:mx-auto md:mt-8 md:w-full md:max-w-screen-md">
        <Search
          onSearch={onSearch}
          placeholder="띄어쓰기 포함하여 검색어를 입력해주세요."
        />

        <Section className="space-y-4">
          <div className="">
            <h6>
              <span className="font-bold">HOT,</span> 키워드
            </h6>
          </div>
          {keywords.map((keyword, index) => (
            <KeywordButton
              key={index}
              onClick={() => push(`/search/${keyword.keyword}`)}
              title={keyword.keyword}
            />
          ))}
        </Section>

        <Section className="space-y-4">
          <h6 className="font-bold">추천 카테고리</h6>
          <HorizontalScrollView className="snap-x snap-mandatory">
            {recommendedCategories.map((category) => (
              <button
                onClick={() => onClickRecommendedCategory(category)}
                key={category.id}
                className="wh-20 mb-2 grid snap-start place-content-center rounded-xl bg-brand-3 px-3 text-center font-semibold shadow-3xl"
              >
                {category.name}
              </button>
            ))}
          </HorizontalScrollView>{' '}
        </Section>

        <Section className="space-y-4">
          <h6 className="font-bold">전체 카테고리</h6>

          <div className="grid grid-cols-2 gap-y-5 text-15 text-gray-700 md:grid-cols-4 md:gap-y-3">
            {categories?.map((category) => (
              <button
                onClick={() => push(`/categories/${category.id}`)}
                key={category.id}
                className="w-max"
              >
                {category.name}
              </button>
            ))}
          </div>
        </Section>
      </div>
    </>
  );
};
