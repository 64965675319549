import { useMutation } from 'react-query';
import {
  CreateNonMemberOrder,
  GetNonMemberOrderRequest,
} from '../types/nonMemberOrders';
import nonMemberOrders from '../api/nonMemberOrders';
import { useExecuteBootpay } from '../hooks/useBootpay';
import { Order, ShippingLineItem } from '../types/orders';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

export const useNonMemberStartOrder = () => {
  const { executeBootpay } = useExecuteBootpay();

  const { mutate: startNonMemberOrder } = useMutation(
    (body: CreateNonMemberOrder) => nonMemberOrders.postOrder(body),
    {
      onSuccess: async (res) => {
        const { data } = res;
        const { payment, shippingLines } = data;

        const items: ShippingLineItem[] = [];
        shippingLines.forEach((shippingLine) => {
          shippingLine.items.forEach((item) => {
            items.push(item);
          });
        });

        const user = {
          name: shippingLines[0].receiverName,
          email: data.email!,
          address: shippingLines[0].address,
          addressDetail: shippingLines[0].addressDetail,
          phone: shippingLines[0].receiverTel,
        };

        executeBootpay(data, payment, items, user);
      },
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
  return {
    startNonMemberOrder,
  };
};

export const useGetNonOrder = ({
  onSuccess,
}: {
  onSuccess: (res: Order) => void;
}) => {
  const { mutate: getNonOrder } = useMutation(
    (body: GetNonMemberOrderRequest) => nonMemberOrders.getOrder(body),
    {
      onSuccess: (res) => {
        const { data } = res;
        onSuccess && onSuccess(data);
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 400) {
          toast('입력하신 정보의 주문 내역이 확인되지 않습니다.', {
            type: 'error',
          });
          return;
        } else {
          toast('오류가 발생하였습니다. 다시 시도해주세요.', {
            type: 'error',
          });
        }
      },
    }
  );
  return {
    getNonOrder,
  };
};

export const useCancelNonOrder = () => {
  const { push } = useHistory();
  const { mutate: cancelNonOrder } = useMutation(
    (body: GetNonMemberOrderRequest) => nonMemberOrders.cancelOrder(body),
    {
      onSuccess: () => {
        toast.success('주문이 취소되었습니다.');
        push('/');
      },
    }
  );
  return {
    cancelNonOrder,
  };
};
