import moment, { Moment } from 'moment';
import { MomentFormat, utcToLocalFormat } from './plugins/moment';
import { DailyRunning } from './types/branch';
import { Courier } from './types/orders';
import { Product, ProductType } from './types/products';
import User from './api/user';
import { CartItem } from './types/cart';

export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export function openChannelIO() {
  window.open('https://arcnbook.channel.io', '_blank');
}

export function displayProductInfo(product: Product, showPublishedAt = false) {
  if (product.type === 'BOOK') {
    if (!product.bookInfo) return '';
    const { authorName, publisherName, publishedAt } = product.bookInfo;
    // TODO: 전체 크롤링 완료되면 updatedAt 비교문 제거
    if (!showPublishedAt || moment(product.updatedAt).isBefore('2022-07-01')) {
      return `${authorName} | ${publisherName}`;
    } else {
      return `${authorName} | ${publisherName} | 출간일 ${utcToLocalFormat(
        publishedAt,
        MomentFormat.Date
      )}`;
    }
  } else {
    return product.company?.name;
  }
}

export function getMomentDailyRunning(dailyRunning?: DailyRunning) {
  if (!dailyRunning)
    return {
      startTime: moment(),
      finishTime: moment(),
    };
  const { startAt, finishAt } = dailyRunning;
  const startTime = moment.utc(startAt).local();
  const finishTime = moment.utc(finishAt).local();
  return {
    startTime,
    finishTime,
  };
}

export function formatStoreRunningTime(startTime: Moment, finishTime: Moment) {
  return `${startTime.format('HH:mm')} ~ ${finishTime.format('HH:mm')}`;
}

export function getCourierName(courier: keyof typeof Courier | undefined) {
  switch (courier) {
    case Courier.CSLOGIS:
      return 'CJ대한통운';
    case Courier.HYUNDAI:
      return '롯데택배';
    case Courier.HANJIN:
      return '한진택배';
    case Courier.EPOST:
      return '우체국';
    case Courier.KGB:
      return '로젠택배';
    case Courier.ILYANG:
      return '일양택배';
    case Courier.TNT:
      return 'TNT';
    case Courier.KDEXP:
      return '경동택배';
    case Courier.CHUNIL:
      return '천일특송';
    case Courier.DAESIN:
      return '대신택배';
    case Courier.HDEXP:
      return '합동택배';
    case Courier.HONAM:
      return '호남택배';
    case Courier.KUNYOUNG:
      return '건영택배';
    case Courier.DIRECT:
      return '업체직송';
    case Courier.DHL:
      return 'DHL';
    default:
      return '업체직송';
  }
}

export function getTrackShippingUrl(
  courier: keyof typeof Courier | undefined,
  waybillCode: string
) {
  switch (courier) {
    case Courier.CSLOGIS:
      return `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${waybillCode}`;
    case Courier.HYUNDAI:
      return `https://www.lotteglogis.com/home/reservation/tracking/linkView?InvNo=${waybillCode}`;
    case Courier.HANJIN:
      return `https://www.hanjin.co.kr/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${waybillCode}`;
    case Courier.EPOST:
      return `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${waybillCode}&displayHeader=N`;
    case Courier.KGB:
      return `https://www.ilogen.com/m/personal/trace/${waybillCode}`;
    case Courier.ILYANG:
      return 'https://www.ilyanglogis.com/functionality/tracking.asp';
    case Courier.TNT:
      return `https://www.tnt.com/express/ko_kr/site/shipping-tools/tracking.html?searchType=con&cons=${waybillCode}`;
    case Courier.KDEXP:
      return 'https://kdexp.com/main.kd';
    case Courier.CHUNIL:
      return `http://www.chunil.co.kr/HTrace/HTrace.jsp?transNo=${waybillCode}`;
    case Courier.DAESIN:
      return 'http://www.ds3211.co.kr/freight/internalFreightForm.jsp';
    case Courier.HDEXP:
      return 'https://hdexp.co.kr/delivery_search.hd';
    case Courier.HONAM:
      return `http://www.honamlogis.co.kr/page/index.php?pid=tracking_number&SLIP_BARCD=${waybillCode}`;
    case Courier.KUNYOUNG:
      return `http://www.kunyoung.com/goods/goods_01.php?mulno=${waybillCode}`;
    case Courier.DHL:
      return `https://www.dhl.com/kr-ko/home/tracking/tracking-parcel.html?submit=1&tracking-id=${waybillCode}`;
    default:
      break;
  }
}

export function maskUserName(name: string) {
  return `${name.slice(0, -1)}*`;
}

export const getUserGradeText = (level: string) => {
  switch (level) {
    case 'PEOPLE':
      return '아크앤피플';
    case 'FRIEND':
      return '아크앤프렌즈';
    case 'FAMILY':
      return '아크앤패밀리';
    default:
      return '아크앤피플';
  }
};

export const getCouponKindText = (kind: string) => {
  switch (kind) {
    case 'BOOK':
      return '도서';
    case 'GENERAL':
      return '잡화';
    default:
      return '잡화';
  }
};

export const getProductTypeText = (productType: string[]) => {
  return productType
    .join(', ')
    .replace('BOOK', '도서')
    .replace('GENERAL', '잡화');
};

export const filterCartItemsByType = (items: CartItem[], type: ProductType) => {
  return items
    .filter((item) => item.product.type === type)
    .sort((a, b) => +a.id - +b.id);
};
