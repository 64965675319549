import React from 'react';
import { useParams } from 'react-router-dom';
import { IdParams } from '../../../types/router';
import { useQuery } from 'react-query';
import companies from '../../../api/companies';
import { ProductCard } from '../../../components/ProductCard';
import { isMobile } from 'react-device-detect';

// TODO
//  - Brand product image url 값 수정 후 확인

const BrandPage = () => {
  const { id } = useParams<IdParams>();
  const { isLoading, data } = useQuery(['company', id], () =>
    companies.getCompanies(Number(id))
  );

  if (!data) return <></>;
  return (
    <div>
      <div
        className={
          'relative isolate aspect-[2/2.5]  w-full bg-cover  bg-no-repeat after:absolute after:inset-0 after:-z-10 after:bg-black after:opacity-50 md:aspect-[3/1]'
        }
        style={{
          backgroundImage: `url(${
            isMobile
              ? data?.data.mobileCompanyImageUrl ??
                'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/company/mobile/1722839218261--gray_brand_mobile.jpeg'
              : // 'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/company/pc/1722838708944--basic_brand_pc.jpeg'
                data?.data.pcCompanyImageUrl ??
                'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/company/mobile/1722839240436--gray_brand_pc.jpeg'
            // 'https://arcnbook-service.s3.ap-northeast-2.amazonaws.com/company/mobile/1722838763283--basic_brand_mobile.jpeg'
          })`,
        }}
      >
        <div
          className={'w-full max-w-screen-xl px-5 py-20 text-white md:mx-auto'}
        >
          <h1 className={''}>{data?.data.name}</h1>
          <div className={'mt-5 md:w-[70%]'}>
            <p className={'font-thin'}>{data?.data.description}</p>
          </div>
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-5 px-5 md:mx-auto md:max-w-screen-xl md:grid-cols-6">
        {data?.data.products.map((product: any) => (
          <div key={product.id}>
            <ProductCard product={product} to={`/product/${product.id}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandPage;
