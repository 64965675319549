import React, { FC, MouseEvent } from 'react';
import { Icon } from './Icon';
import { ReactComponent as OrangeBookmark } from '../assets/svg/orange-bookmark.svg';
import { useHistory } from 'react-router-dom';
import { useAddProductToCart } from '../queries/cart';
import { SoldOutOverlay } from './SoldOutOverlay';
import { Product } from '../types/products';
import { displayProductInfo } from '../utils';
import { useAddProductToNonCart } from '../queries/nonMemberCarts';
import useAuth from '../hooks/useAuth';

interface ProductCardProps {
  product: Product;
  showRanking?: boolean;
  to?: string;
}

// const ageVerified = false;
// const login = false;

export const ProductCard: FC<ProductCardProps> = ({
  product,
  showRanking,
  to,
}) => {
  const { authenticated } = useAuth();

  const { push } = useHistory();
  const { addProductToCart } = useAddProductToCart();
  const { addProductToNonCart } = useAddProductToNonCart();

  const onClickAddCart = (e: MouseEvent) => {
    e.stopPropagation();
    if (authenticated) {
      addProductToCart({ product });
      return;
    }
    addProductToNonCart({ product });
    return;
  };
  return (
    <div className="flex-col space-y-2">
      <div
        onClick={() => push(`${to}`)}
        // onClick={() =>
        //   !login && product.viewRestricted
        //     ? restrictedViewLoginRequest()
        //     : !ageVerified && product.viewRestricted
        //     ? verifyAgeRequest()
        //     : push(`${to}`)
        // }
        className="relative aspect-[3/4.5] cursor-pointer overflow-hidden rounded-xl"
      >
        {showRanking &&
        product.categoryRank <= 10 &&
        product.categoryRank > 0 ? (
          <>
            <div className="absolute left-5 z-10">
              <span className="absolute top-0.5 left-[7px] text-white">
                {product.categoryRank}
              </span>
              <OrangeBookmark className="" />
            </div>
          </>
        ) : product.isNew ? (
          <div className="absolute top-3 left-3 z-10 h-fit w-max rounded-full bg-brand-1 px-3 py-1 text-12 font-semibold text-white">
            NEW
          </div>
        ) : (
          <></>
        )}

        <button className="wh-10 absolute bottom-3 right-3 z-10 grid place-content-center rounded-full bg-black bg-opacity-50">
          <Icon.ShoppingCart
            className="wh-5 text-white"
            onClick={onClickAddCart}
          />
        </button>
        {/*
        {!ageVerified && product.viewRestricted && (
          <RatedSvg className="absolute-center z-10 w-2/3" />
        )} */}

        <img
          src={product.images[0]}
          alt=""
          // className={`${
          //   !ageVerified && product.viewRestricted && 'blur-lg'
          // } h-full w-full object-cover `}
          className="h-full w-full object-cover"
        />
        {product.isSoldOut && <SoldOutOverlay />}
      </div>

      <div className="text-13 font-bold truncate-2-lines">{product.name}</div>

      <div className="flex-1" />

      <p
        className={`text-12 text-gray-500 line-clamp-1 ${
          product.type === 'GENERAL' && 'cursor-pointer'
        }`}
        onClick={() =>
          product.type === 'GENERAL' && push('/brand/' + product.companyId)
        }
      >
        {displayProductInfo(product)}
      </p>

      <ProductCardPrice product={product} />

      <div className="flex items-center space-x-3">
        <div className="flex items-center space-x-1">
          <Icon.Star className="wh-3 fill-current text-[#FED188]" />
          <span className="text-10 text-gray-300">
            {product.avgScore} ({product.reviewCount})
          </span>
        </div>

        <div className="flex items-center space-x-1">
          <Icon.BookmarkFilled className="h-2.5 w-2.5 text-[#FED188]" />
          <span className="text-10 text-gray-300">{product.bookmarkCount}</span>
        </div>
      </div>
    </div>
  );
};

interface ProductCardPriceProps {
  product: Product;
  containerClass?: string;
}

export const ProductCardPrice: FC<ProductCardPriceProps> = ({
  product,
  containerClass = 'text-12',
}) => {
  return (
    <div className={containerClass}>
      {product.discountAmount > 0 ? (
        <>
          <div>
            <span className="text-12 text-gray-500 line-through">
              {product.regularPrice.toLocaleString()}원
            </span>
            <span className="mr-1 font-bold text-red-500">
              {product.discountType === 'RATE'
                ? `${product.discountAmount}%`
                : `${Math.floor(
                    (product.discountAmount / product.regularPrice) * 100
                  )}%`}
            </span>
          </div>
        </>
      ) : (
        ''
      )}
      <span className="font-bold">{product.price.toLocaleString()}원</span>
    </div>
  );
};
