import React, { Dispatch, FC, useEffect, useState } from 'react';
import { AddressCard } from '../AddressCard';
import { AddressFormCard } from '../AddressFormCard';
import { Button } from '../Button';
import { Icon } from '../Icon';
import {
  useCreateShippingAddress,
  useShippingAddresses,
} from '../../queries/shippingAddress';
import { ShippingAddress } from '../../types/shippingAddress';

interface ShippingAddressModalProps {
  open: boolean;
  onClose: () => void;
  selectedAddressId: number;
  onCheckAddress: Dispatch<React.SetStateAction<number>>;
}

export const ShippingAddressModal: FC<ShippingAddressModalProps> = ({
  open,
  onClose,
  selectedAddressId,
  onCheckAddress,
}) => {
  const [addAddress, setAddAddress] = useState(false);
  const { shippingAddresses, isLoading } = useShippingAddresses();
  const { createShippingAddress } = useCreateShippingAddress((data) => {
    if (shippingAddresses.length === 0) {
      onCheckAddress(data.id);
    }
    setAddAddress(false);
  });
  useEffect(() => {
    if (isLoading) return;
    if (shippingAddresses.length > 0) return;
    setAddAddress(true);
  }, [shippingAddresses, isLoading]);

  if (!open) return <></>;
  return (
    <div className=" fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">
      <div className="no-scrollbar flex h-screen w-full max-w-md flex-col space-y-4 overflow-hidden overflow-y-auto bg-white py-8 px-3 md:max-h-[80vh] md:rounded-xl md:px-8">
        <div className="flex items-center justify-between px-4 md:px-0">
          <h5>배송지 관리</h5>

          <button onClick={onClose}>
            <Icon.X />
          </button>
        </div>
        <div className="space-y-8 p-5 md:px-0">
          {shippingAddresses.map((address) => (
            <div className="" key={address.id}>
              <AddressCard
                address={address}
                checked={(address: ShippingAddress) =>
                  address.id === selectedAddressId
                }
                onCheckAddress={(id: number) => {
                  onCheckAddress(id);
                  onClose();
                }}
              />
            </div>
          ))}

          {addAddress && (
            <AddressFormCard
              onSave={createShippingAddress}
              onCancel={() => setAddAddress(false)}
            />
          )}

          {!addAddress && (
            <Button
              onClick={() => setAddAddress(true)}
              className="flex w-full items-center justify-center space-x-2 bg-gray-100 text-gray-700"
            >
              <span className="">주소지 추가하기 </span>
              <Icon.PlusCircle />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
