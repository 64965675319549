import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import API from '../api';
import useAuth from '../hooks/useAuth';
import { EventTag } from '../types/eventTag';
import { BookInfo, Product } from '../types/products';
import { Review } from '../types/review';

export const useProduct = (id: string) => {
  const { data, refetch } = useQuery(['product', id], () => API.getProduct(id));

  const product = data?.data as Product;
  const bookInfo = product?.bookInfo as BookInfo;

  const refinedProduct: Product = {
    ...product,
    bookInfo: {
      ...bookInfo,
      descPub: bookInfo?.descPub?.replaceAll('\r\n', '<br />'),
      descTable: bookInfo?.descTable?.replaceAll('\r\n', '<br />'),
    },
  };

  return {
    product: refinedProduct,
    refetch,
  };
};

export const useProductReviews = (id: string) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['product', id, 'reviews'],
    ({ pageParam = 1 }) => API.getProductReviews(id, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        const pagination = lastPage.data.pagination;
        if (pagination.currentPage < pagination.totalPage)
          return pagination.currentPage + 1;
        return undefined;
      },
    }
  );
  if (isLoading || !data)
    return {
      reviews: [] as Review[],
      fetchNextPage: () => {},
      hasNextPage: false,
    };

  return {
    reviews: data?.pages.flatMap((page) => page.data.items),
    totalItemCount: data?.pages[0]?.data.pagination.totalItemCount,
    isLoading,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  };
};

export const useProductEventTags = (id: string) => {
  const { data, isLoading } = useQuery(['product', id, 'eventTags'], () =>
    API.getProductEventTags(id)
  );

  if (isLoading || !data?.data) {
    return {
      eventTags: [] as EventTag[],
    };
  }

  return {
    eventTags: data.data,
  };
};

export const useProducts = (
  categoryId: number,
  sortBy = 'rank',
  where = {}
) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['products', categoryId, sortBy, JSON.stringify(where)],
    ({ pageParam = 1 }) =>
      API.getProducts(categoryId, sortBy, pageParam, where),
    {
      getNextPageParam: (lastPage, pages) => {
        const pagination = lastPage.data.pagination;
        if (pagination.currentPage < pagination.totalPage)
          return pagination.currentPage + 1;
        return undefined;
      },
    }
  );
  if (isLoading)
    return {
      products: [],
      fetchNextPage: () => {},
      hasNextPage: false,
    };

  return {
    products: data?.pages.flatMap((page) => page.data.items),
    totalItemCount: data?.pages[0]?.data.pagination.totalItemCount,
    isLoading,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  };
};

export const useProductsByIds = (productIds: number[]) => {
  const { data, isLoading } = useQuery(['products', productIds], () => {
    if (productIds.length === 0) return;
    return API.getProductsByIds(productIds);
  });
  if (isLoading)
    return {
      products: [],
    };

  return {
    products: data?.data.items,
    totalItemCount: data?.data.pagination.totalItemCount,
  };
};

export const useProductsSearch = (
  keyword: string,
  sortBy = 'rank',
  type?: 'all' | 'book' | 'general'
) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['productsSearch', keyword, sortBy, type],
    ({ pageParam = 1 }) =>
      API.getProductsSearch(keyword, sortBy, pageParam, type),
    {
      getNextPageParam: (lastPage, pages) => {
        const pagination = lastPage.data.pagination;
        if (pagination.currentPage < pagination.totalPage)
          return pagination.currentPage + 1;
        return undefined;
      },
    }
  );
  if (isLoading)
    return {
      products: [],
      fetchNextPage: () => {},
      hasNextPage: false,
      isLoading,
    };

  return {
    products: data?.pages.flatMap((page) => page.data.items),
    totalItemCount: data?.pages[0]?.data.pagination.totalItemCount,
    isLoading,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  };
};

export const useBookmarkProducts = () => {
  const { authenticated } = useAuth();
  const { data, refetch } = useQuery(
    ['bookmarkProducts'],
    () => API.getBookmarkProducts(),
    {
      enabled: authenticated,
    }
  );

  if (!data?.data) {
    return {
      bookmarks: [] as Product[],
    };
  }

  return {
    bookmarks: data.data.items,
    refetch,
  };
};

export const useAddProductToBookmark = (productId: number) => {
  const queryClient = useQueryClient();
  const { mutate: addProductToBookmark } = useMutation(
    () => API.postBookmarkProduct(productId),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['product', productId.toString()]);
        queryClient.refetchQueries(['bookmarkProducts']);
      },
    }
  );
  return {
    addProductToBookmark,
  };
};

export const useRemoveProductToBookmark = (productId: number) => {
  const queryClient = useQueryClient();
  const { mutate: removeProductToBookmark } = useMutation(
    () => API.deleteBookmarkProduct(productId),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['product', productId.toString()]);
        queryClient.refetchQueries(['bookmarkProducts']);
      },
    }
  );
  return {
    removeProductToBookmark,
  };
};
