import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import { useForm } from 'react-hook-form';
import { OrderHistoryProducts } from '../../components/orderhistorydetail/OrderHistoryProducts';
import { useGetNonOrder } from '../../queries/nonMemberOrders';
import { Order } from '../../types/orders';
import { PaymentInfo } from '../../components/orderhistorydetail/PaymentInfo';
import { OrderInfo } from '../../components/orderhistorydetail/OrderInfo';
import { openChannelIO } from '../../utils';

interface NonMemberForm {
  code: string;
  receiverName: string;
  receiverTel: string;
}

export const NonMemberPage = () => {
  const [orderData, setOrderData] = useState<any>(null);
  const [isComplete, setComplete] = useState(false);
  const { getNonOrder } = useGetNonOrder({
    onSuccess: (res) => {
      console.log('getNonOrder Response', res);
      const order: Order = res;
      const books = order.shippingLines.filter((line) =>
        line.items.some((item) => item.productType === 'BOOK')
      );
      const generals = order.shippingLines.filter((line) =>
        line.items.some((item) => item.productType === 'GENERAL')
      );
      setOrderData({
        order,
        books,
        generals,
      });
      setComplete(true);
    },
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<NonMemberForm>();

  const onSubmit = (data: NonMemberForm) => {
    getNonOrder(data);
  };

  return (
    <div className="">
      <div
        className={`mx-auto md:my-12 md:w-full ${
          isComplete ? 'md:max-w-screen-lg' : 'md:max-w-md'
        }`}
      >
        <TopBar title="비회원 주문조회" />
        {isComplete ? (
          <div className="mx-5 mt-4 space-y-6 md:mx-0 md:flex md:space-y-0 md:space-x-8">
            <OrderHistoryProducts
              books={orderData.books ?? []}
              generals={orderData.generals ?? []}
            />
            <div className="space-y-6 md:w-full">
              <PaymentInfo order={orderData.order} />

              <OrderInfo order={orderData.order} />

              <button
                className="w-full text-gray-600 underline"
                onClick={openChannelIO}
              >
                교환 • 환불 문의하기
              </button>
            </div>
          </div>
        ) : (
          <form className="mt-4 space-y-9 px-5 pt-6 md:px-0">
            <TextField
              label="이름"
              peerStyle={false}
              placeholder="이름을 입력해주세요."
              helper={errors.receiverName?.message}
              {...register('receiverName', {
                required: '이름을 입력해주세요',
                // pattern: {
                //   value: /^\S+$/,
                //   message: '띄어쓰기를 제외하고 입력해주세요.',
                // },
              })}
            />

            <TextField
              label="주문번호"
              peerStyle={false}
              placeholder="주문번호를 입력해주세요."
              helper={errors.code?.message}
              {...register('code', {
                required: '주문번호를 입력해주세요',
                pattern: {
                  value: /^\S+$/,
                  message: '띄어쓰기를 제외하고 입력해주세요.',
                },
              })}
            />

            <TextField
              type="text"
              label="연락처"
              peerStyle={false}
              placeholder={'"-"를 제외하고 입력해주세요.'}
              helper={errors.receiverTel?.message}
              {...register('receiverTel', {
                required: '연락처를 입력해주세요',
                pattern: {
                  value: /^\d{10,11}$/,
                  message: '올바른 연락처 형식을 입력해주세요.',
                },
              })}
            />
            <Button
              // disabled
              text="주문 조회하기"
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="filled-gray-900 w-full"
            />
          </form>
        )}
      </div>
    </div>
  );
};
