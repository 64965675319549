import React, { FC } from 'react';
import { Icon } from './Icon';
import {
  useAddProductToBookmark,
  useBookmarkProducts,
  useRemoveProductToBookmark,
} from '../queries/products';
import { useModal } from './modal/Modal';
import useAuth from '../hooks/useAuth';

interface BookmarkButtonProps {
  productId?: number;
}

export const BookmarkButton: FC<BookmarkButtonProps> = ({ productId }) => {
  const { bookmarks } = useBookmarkProducts();
  const { addProductToBookmark } = useAddProductToBookmark(productId ?? 1);
  const { removeProductToBookmark } = useRemoveProductToBookmark(
    productId ?? 1
  );
  const { authenticated } = useAuth();
  const { requireLogin } = useModal();
  const isBookmarked = bookmarks.some((bookmark) => bookmark.id === productId);
  const onClickBookmark = () => {
    if (!authenticated) {
      return requireLogin('북마크');
    }
    if (isBookmarked) {
      removeProductToBookmark();
    } else {
      addProductToBookmark();
    }
  };
  return (
    <>
      <button
        onClick={onClickBookmark}
        className={`grid h-12 w-14 place-content-center rounded-xl border border-gray-100 p-2`}
      >
        <div className={'h-[24px] w-[24px]'}>
          {isBookmarked ? (
            <Icon.BookmarkFilled2 className={'h-full w-full'} />
          ) : (
            <Icon.Bookmark className={'h-full w-full'} />
          )}
        </div>
      </button>
    </>
  );
};
