import { CSSProperties } from 'react';
import DaumPostCode from 'react-daum-postcode';
import { isMobile } from 'react-device-detect';
import { Icon } from './Icon';

export interface DaumPostResult {
  address: string;
  zonecode: string;
}

interface DaumPostProps {
  address: string;
  onComplete: (data: DaumPostResult) => void;
  open: boolean;
  onClose: () => void;
}

export const DaumPost = ({
  address,
  onComplete,
  open,
  onClose,
}: DaumPostProps) => {
  const onCompletePost = (data: DaumPostResult) => {
    onComplete(data);
    onClose();
  };

  const postCodeStyle: CSSProperties = {
    display: 'block',
    padding: `${isMobile ? '0' : '5px'}`,
    height: `${isMobile ? '100%' : '480px'}`,
    maxWidth: '720px',
  };

  return open ? (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-50"
      onClick={onClose}
    >
      {isMobile && (
        <div
          onClick={onClose}
          className={'flex h-12 items-center justify-end bg-white px-2'}
        >
          <Icon.X className={'wh-6'} />
        </div>
      )}
      <div className={'flex h-full w-full items-center justify-center'}>
        <DaumPostCode
          style={postCodeStyle}
          onComplete={onCompletePost}
          defaultQuery={address}
          autoClose
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
