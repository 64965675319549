import base from './base';
import { Cart, CartItemShallow, ChangeCartItemAmount } from '../types/cart';

// NOTE: branchCode 는 뭐지?
const nonMemberCarts = {
  getCart: () => {
    return base.get<Cart>('/non-member/cart');
  },
  postCartItems: (productId: number, body: ChangeCartItemAmount) => {
    const url = `/non-member/cart/${productId}`;
    return base.post<CartItemShallow>(url, body);
  },
  deleteCartItems: (productId: number, body: ChangeCartItemAmount) => {
    const url = `/non-member/cart/${productId}`;
    return base.delete<CartItemShallow>(url, {
      data: body,
    });
  },
  removeOrderItems: (body: number[]) => {
    return base.delete('/non-member/cart/delete/multiple', {
      data: {
        cartIds: body,
      },
    });
  },
};

export default nonMemberCarts;
