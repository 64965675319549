import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useDeleteShippingAddress,
  useUpdateShippingAddress,
} from '../queries/shippingAddress';
import {
  CreateShippingAddress,
  ShippingAddress,
} from '../types/shippingAddress';
import { AddressFormCard } from './AddressFormCard';
import { CheckCircle } from './CheckCircle';
import { modalState } from '../plugins/ridge';

interface AddressCardProps {
  address?: ShippingAddress;
  checked?: (address: ShippingAddress) => boolean;
  onCheckAddress?: (id: number) => void;
}

export const AddressCard: FC<AddressCardProps> = ({
  address,
  checked,
  onCheckAddress,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const { deleteShippingAddress } = useDeleteShippingAddress(() => {
    toast('배송지가 삭제되었습니다.', {
      type: 'success',
    });
    modalState.set((prev) => {
      return {
        ...prev,
        isShow: false,
      };
    });
  });
  const { updateShippingAddress } = useUpdateShippingAddress(() => {
    setIsEdit(false);
    toast.success('배송지가 변경되었습니다.');
  });
  if (!address) return <></>; // TODO: required로 변경

  const onChange = () => {
    if (!onCheckAddress) return;
    if (!checked || checked(address)) return;
    onCheckAddress(address.id);
  };

  const onSave = (data: CreateShippingAddress) => {
    updateShippingAddress({
      id: address.id,
      data,
    });
  };

  const onDelete = () => {
    if (address.isDefault) {
      toast('기본 배송지는 삭제할 수 없습니다.', {
        type: 'error',
      });
      return;
    }
    modalState.set((prev) => {
      return {
        ...prev,
        isShow: true,
        title: '배송지 삭제',
        bodyText: `${address.title} 배송지를 삭제하시겠습니까?`,
        primaryButtonText: '삭제',
        primaryClick: () => deleteShippingAddress(address.id),
      };
    });
  };

  return (
    <>
      {isEdit ? (
        <AddressFormCard
          onSave={onSave}
          onCancel={() => setIsEdit(false)}
          address={address}
        />
      ) : (
        <>
          {checked && (
            <CheckCircle checked={checked(address)} onClick={onChange} />
          )}
          <div className="card-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between space-x-3">
                <h6 className="font-bold">{address.title}</h6>

                {address.isDefault && (
                  <div className="rounded-full border-2 border-brand-2 px-2 py-1 text-14 font-bold text-brand-2">
                    기본배송지
                  </div>
                )}
              </div>
              <div className={'flex  items-center'}>
                <button
                  onClick={() => setIsEdit(true)}
                  className="px-2 text-13 text-gray-600"
                >
                  수정
                </button>
                <button
                  onClick={onDelete}
                  className="px-2 text-13 text-gray-600"
                >
                  삭제
                </button>
              </div>
            </div>

            <p className="pt-4 text-13 text-gray-600">
              {address.address} {address.addressDetail} <br />
              {address.publicDoorPassword} | {address.receiverName} |{' '}
              {address.receiverTel}
            </p>
          </div>
        </>
      )}
    </>
  );
};
