import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AuthRoute } from '../components/AuthRoute';
import { LoginPage } from './login/LoginPage';
import { HomePage } from './home/HomePage';
import { SignupPage } from './signup/SignupPage';
import { BottomNavBar } from '../components/BottomNavBar';
import { NonMemberPage } from './non-member/NonMemberPage';
import { FindUserNamePage } from './username/FindUserNamePage';
import { FindUserNameSuccessPage } from './username/FindUserNameSuccessPage';
import { FindPasswordPage } from './passwords/FindPasswordPage';
import { CategoriesPage } from './categories/CategoriesPage';
import { CategoryPage } from './categories/[id]/CategoryPage';
import { SearchResultsPage } from './search/[id]/SearchResultsPage';
import { BookmarksPage } from './bookmarks/BookmarksPage';
import { MyPage } from './mypage/MyPage';
import { MyMileagePage } from './mypage/mileage/MyMileagePage';
import { EditMyPage } from './mypage/edit/EditMyPage';
import { ListsPage } from './list/ListsPage';
import { CartPage } from './cart/CartPage';
import { CartNonMemberPage } from './cart/non-member/CartNonMemberPage';
import { CartNonMemberSuccessPage } from './cart/non-member/success/CartNonMemberSuccessPage';
import { CartPayPage } from './cart/pay/CartPayPage';
import { CartPaySuccessPage } from './cart/pay/success/CartPaySuccessPage';
import { ShippingAddressPage } from './shipping-address/ShippingAddressPage';
import { OrderHistoryPage } from './order-history/OrderHistoryPage';
import { OrderHistoryDetailPage } from './order-history/[id]/OrderHistoryDetailPage';
import { MyReviewsPage } from './mypage/reviews/MyReviewsPage';
import {
  CreateMyReviewPage,
  EditMyReviewPage,
} from './mypage/reviews/[id]/CreateMyReviewPage';
import { ProductPage } from './product/ProductPage';
import { Footer } from '../components/Footer';
import { TopNavBar } from '../components/TopNavBar';
import { ArcNShop } from './arcnshop/ArcNShop';
import { CurationPage } from './curations/[id]/CurationPage';
import { CartPayStorePickUpPage } from './cart/pay/store-pickup/CartPayStorePickUpPage';
import { GradeInformationPage } from './mypage/grade-information/GradeInformationPage';
import { MyCouponPage } from './mypage/coupon/MyCouponPage';
import { PartnershipPage } from './partnership';
import { PartnerShipFormPage } from './partnership/form';
import BrandPage from './brand/[id]/BrandPage';

export const homeRoute = '/home';
export const categoryRoute = '/categories';
export const bookmarkRoute = '/bookmark';
export const myPageRoute = '/mypage';
export const shippingAddressRoute = '/shipping-address';

export const Router = () => {
  const { pathname } = useLocation();
  const mobileTopNavBar =
    pathname === '/home' ||
    pathname === '/arcnshop' ||
    pathname === '/lists/:id' ||
    pathname === '/lists/:id';
  return (
    <>
      <div
        className={`${
          !mobileTopNavBar && 'md:block'
        } sticky top-0 z-20 bg-white`}
      >
        <TopNavBar />
      </div>

      <div className="flex min-h-screen flex-1 flex-col">
        <main className="relative flex flex-1 flex-col">
          <Switch>
            <AuthRoute path="/signup" component={SignupPage} guestOnly />
            <AuthRoute path="/login" component={LoginPage} guestOnly />
            <Route path="/passwords/find" component={FindPasswordPage} />
            <AuthRoute
              path="/mypage/reviews/:id/create"
              component={CreateMyReviewPage}
            />
            <AuthRoute
              path="/mypage/reviews/edit/:id"
              component={EditMyReviewPage}
            />
            <Route
              path="/mypage/grade-information"
              component={GradeInformationPage}
            />
            <Route path="/mypage/reviews" component={MyReviewsPage} />
            <Route
              path={shippingAddressRoute}
              component={ShippingAddressPage}
            />
            <Route
              path="/username/find/success"
              component={FindUserNameSuccessPage}
            />

            <Route
              path="/cart/non-member/success"
              component={CartNonMemberSuccessPage}
            />
            <AuthRoute
              path="/order-history/:id"
              component={OrderHistoryDetailPage}
            />
            <AuthRoute path="/order-history" component={OrderHistoryPage} />
            <Route path="/cart/non-member" component={CartNonMemberPage} />
            <Route path="/cart/pay/success" component={CartPaySuccessPage} />
            <Route
              path="/cart/pay/store-pickup"
              component={CartPayStorePickUpPage}
            />
            <Route path={`/brand/:id`} component={BrandPage} />
            <Route path="/cart/pay" component={CartPayPage} />
            <Route path="/cart" component={CartPage} />
            <Route path="/product/:id" component={ProductPage} />
            <Route path="/lists/:id" component={ListsPage} />
            <Route path="/username/find" component={FindUserNamePage} />
            <Route path="/non-member" component={NonMemberPage} />
            <AuthRoute path="/mypage/coupon" component={MyCouponPage} />
            <AuthRoute path="/mypage/mileage" component={MyMileagePage} />
            <AuthRoute path="/mypage/edit" component={EditMyPage} />
            <AuthRoute path={myPageRoute} component={MyPage} />
            <AuthRoute path={bookmarkRoute} component={BookmarksPage} />
            <Route path={`/search/:keyword`} component={SearchResultsPage} />
            <Route path="/search" component={CategoriesPage} />
            <Route path="/arcnshop" component={ArcNShop} />
            <Route path={`/categories/:id`} component={CategoryPage} />
            <Route path={`/curations/:id`} component={CurationPage} />
            <Route path={`/partnership/form`} component={PartnerShipFormPage} />
            <Route path={`/partnership`} component={PartnershipPage} />
            <Route path={categoryRoute} component={CategoriesPage} />
            <Route path={homeRoute} component={HomePage} />
            <Route path="/">
              <Redirect to={homeRoute} />
            </Route>
          </Switch>
        </main>

        <div className="mt-24 md:hidden">
          <Switch>
            <Route
              path={[homeRoute, categoryRoute, bookmarkRoute, myPageRoute]}
              component={BottomNavBar}
            />
          </Switch>
        </div>

        <Footer />
      </div>
    </>
  );
};
