import { GridCardProduct, GridCardProductType } from '../types/gridCardProduct';
import base from './base';

const gridCardProducts = {
  getGridCardProducts(
    type: keyof typeof GridCardProductType,
    position: 'MAIN' | 'LIFE'
  ) {
    return base.get<GridCardProduct[]>('grid-card-products', {
      params: {
        type,
        position,
      },
    });
  },
};

export default gridCardProducts;
