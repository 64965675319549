import React from 'react';
import { ProductCard } from '../../components/ProductCard';
import { Header } from './Header';

import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { Section } from '../../components/Section';
import { Icon } from '../../components/Icon';
import { HorizontalScrollView } from '../../components/HorizontalScrollView';
import { SectionDto } from '../../types/sections';
import { goToUrl } from './utils';

interface MultiCardSectionProps {
  section: SectionDto;
}

export const MultiCardSection = ({ section }: MultiCardSectionProps) => {
  return (
    <>
      <div className="mt-12 space-y-4">
        <Header
          title={section.title}
          description={section.description}
          onClick={() => goToUrl(section.clickUrl)}
        />

        <HorizontalScrollView
          alignTop
          className="snap-x snap-mandatory md:hidden"
        >
          {section.products?.map((p) => (
            <div key={p.id} className="w-[28vw] snap-start md:w-[200px]">
              <ProductCard
                to={`/product/${p.product.id}`}
                product={p.product}
                showRanking={false}
              />
            </div>
          ))}
        </HorizontalScrollView>

        <Section className="relative hidden w-full md:block">
          {section.products.length > 0 && (
            <Swiper
              navigation={{
                nextEl: `.swiper-button-next-${section.id}`,
                prevEl: `.swiper-button-prev-${section.id}`,
              }}
              loop
              modules={[Navigation]}
              className="mySwiper"
              spaceBetween={10}
              slidesPerView={3.7}
              breakpoints={{
                640: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1280: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
            >
              {section.products.map((p) => (
                <SwiperSlide key={p.id}>
                  <div className="">
                    <ProductCard
                      to={`/product/${p.product.id}`}
                      product={p.product}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}

          <button
            className={`swiper-button-prev-${section.id} wh-10 absolute -left-10 top-36 hidden md:block`}
          >
            <Icon.ChevronLeft />
          </button>
          <button
            className={`swiper-button-next-${section.id} wh-10 absolute -right-10 top-36 hidden place-content-center md:grid`}
          >
            <Icon.ChevronRight />
          </button>
        </Section>
      </div>
    </>
  );
};
