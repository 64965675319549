import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCartContext } from '../../context/cart';
import { modalState } from '../../plugins/ridge';
import {
  useAddProductToCart,
  useRemoveProductToCart,
} from '../../queries/cart';
import { CartItem } from '../../types/cart';
import { displayProductInfo } from '../../utils';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icon';
import { ProductCardPrice } from '../ProductCard';
import { SoldOutOverlay } from '../SoldOutOverlay';
import { useAuth } from '../../hooks';
import { useRemoveProductToNonCart } from '../../queries/nonMemberCarts';

interface CartCardProps {
  item: CartItem;
  remainQuantity?: number;
  isPickup?: boolean;
}

export const CartCard: FC<CartCardProps> = ({
  item,
  remainQuantity,
  isPickup = false,
}) => {
  const { authenticated } = useAuth();
  const { push } = useHistory();
  const { product, quantity } = item;
  const { addProductToCart } = useAddProductToCart(false);
  const { removeProductToCart } = useRemoveProductToCart();
  const { removeProductToNonCart } = useRemoveProductToNonCart();

  const checkRemoveAll = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    modalState.set({
      title: '장바구니 제거',
      bodyText: '선택하신 상품을 장바구니에서 삭제하시겠습니까?',
      primaryButtonText: '확인',
      primaryClick: () =>
        removeProductToCart({
          product,
          cartQuantity: quantity,
          branchCode: item.branchCode,
        }),
      secondaryButtonText: '취소',
    });
  };

  const isSoldOut = isPickup
    ? remainQuantity === 0
    : product.isSoldOut || remainQuantity === 0;

  const { toggleSelect, checkIsSelected } = useCartContext();
  const onToggleCheckbox = () => {
    if (isSoldOut) return;
    toggleSelect(item);
  };

  const goToProduct = () => push(`/product/${product.id}`);

  const addProduct = () => {
    if ((remainQuantity && quantity >= remainQuantity) || isSoldOut) {
      toast.info('재고가 부족합니다.');
      return;
    }
    addProductToCart({ product, branchCode: item.branchCode });
  };

  const decreaseProduct = () => {
    if (quantity <= 1) {
      return;
    }
    authenticated
      ? removeProductToCart({
          product,
          branchCode: item.branchCode,
        })
      : removeProductToNonCart({
          product,
        });
  };

  return (
    <div className="space-y-4">
      <Checkbox
        onChange={onToggleCheckbox}
        checked={checkIsSelected(item)}
        disabled={isSoldOut}
      />

      <div
        style={{
          backgroundImage: `url(${product.images[0]})`,
        }}
        className={`relative aspect-[3/4.5] overflow-hidden rounded-xl bg-brand-3 bg-cover bg-center bg-no-repeat`}
        onClick={goToProduct}
      >
        {isSoldOut && <SoldOutOverlay />}
        {product.promotionId > 0 && (
          <div className="absolute top-3 left-3 z-10 h-fit w-max rounded-full bg-brand-1 px-3 py-1 text-12 font-semibold text-white">
            이벤트
          </div>
        )}
        <div className="wh-7 absolute bottom-4 right-4 grid place-content-center rounded-full border border-white bg-black text-white">
          <Icon.Trash className="wh-4 " onClick={(e) => checkRemoveAll(e)} />
        </div>
      </div>

      <div className="space-y-1">
        <div className="text-13 font-bold truncate-2-lines">
          {product.name}{' '}
          {isSoldOut && <span className="text-13 text-red-500">(품절)</span>}
        </div>
        <div className="flex-1" />

        <p className="text-12 text-gray-500 line-clamp-1">
          {displayProductInfo(product)}
        </p>

        <ProductCardPrice product={product} />

        <div className="flex items-center space-x-3 pt-4">
          {product.promotionId ? (
            <div className="wh-7"></div>
          ) : (
            <button
              className="wh-7 grid place-content-center rounded-full text-20 font-bold shadow-3xl"
              onClick={decreaseProduct}
            >
              -
            </button>
          )}
          <span className="text-13">{quantity}</span>
          {product.promotionId ? (
            <div className="wh-7"></div>
          ) : (
            <button
              className="wh-7 grid place-content-center rounded-full text-14 font-bold shadow-3xl"
              onClick={addProduct}
            >
              +
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
