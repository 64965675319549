import React from 'react';
import { OrderHistoryProducts } from '../../../components/orderhistorydetail/OrderHistoryProducts';
import { OrderInfo } from '../../../components/orderhistorydetail/OrderInfo';
import { PaymentInfo } from '../../../components/orderhistorydetail/PaymentInfo';
import { TopBar } from '../../../components/TopBar';
import { openChannelIO } from '../../../utils';
import { useParams } from 'react-router-dom';
import { IdParams } from '../../../types/router';
import { useOrderDetail } from '../../../queries/orders';

export const OrderHistoryDetailPage = () => {
  const { id } = useParams<IdParams>();
  const { books, generals, order } = useOrderDetail(+id);
  return (
    <div className="mt-8 md:mx-auto md:w-full md:max-w-screen-lg">
      <TopBar title="주문 내역 상세" />

      <div className="mx-5 mt-4 space-y-6 md:mx-0 md:flex md:space-y-0 md:space-x-8">
        <OrderHistoryProducts books={books} generals={generals} />

        <div className="space-y-6 md:w-full">
          <PaymentInfo order={order} />

          <OrderInfo order={order} />

          <button
            className="w-full text-gray-600 underline"
            onClick={openChannelIO}
          >
            교환 • 환불 문의하기
          </button>
        </div>
      </div>
    </div>
  );
};
