import axios, { AxiosError } from 'axios';
import React, {
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { forwardRef } from 'react';
import { toast } from 'react-toastify';
import API from '../api';
import useTimer from '../hooks/useTimer';
import { LibroUserInfo } from '../types/user';
import { Button } from './Button';
import { TextField } from './TextField';

const rmSpace = (v: string) => v.replace(/\D/g, '');

export interface PhoneNumberProps
  extends InputHTMLAttributes<HTMLInputElement> {
  onVerified: (phone: string) => void;
  label?: string;
  helper?: string;
  initialValue?: string; // 전화번호 수정 시 비교를 위한 현재 사용자 번호
  shouldCheckExist?: boolean;
  onEdit?: (isEdit: boolean) => void; // 전화번호 수정 상태인 경우 콜백
  onGetLibroUser?: (userInfo: LibroUserInfo) => void;
}

export const PhoneNumber = forwardRef<HTMLInputElement, PhoneNumberProps>(
  (
    {
      onVerified,
      initialValue,
      shouldCheckExist,
      onEdit,
      onGetLibroUser,
      ...props
    },
    ref
  ) => {
    const [number1, setNumber1] = useState('');
    const [number2, setNumber2] = useState('');
    const [number3, setNumber3] = useState('');

    const [codeSent, setCodeSent] = useState(false);
    const [authNumber, setAuthNumber] = useState('');
    const [verified, setVerified] = useState(false);
    const [verifiedNumber, setVerifiedNumber] = useState('');

    const [expiredAt, setExpiredAt] = useState('0');
    const [minutes, seconds] = useTimer(expiredAt);

    const phone = useMemo(() => {
      return `${number1}-${number2}-${number3}`;
    }, [number1, number2, number3]);

    useEffect(() => {
      if (!initialValue) return;
      const numbers = initialValue.split('-');
      setNumber1(numbers[0]);
      setNumber2(numbers[1]);
      setNumber3(numbers[2]);
    }, [initialValue]);

    useEffect(() => {
      // 인증 받은 후 phone 번호 바뀌면 인증 무효화
      if (!verifiedNumber) return;
      if (phone !== verifiedNumber) {
        setVerified(false);
        setCodeSent(false);
      } else {
        setVerified(true);
        setCodeSent(true);
      }
    }, [phone, verifiedNumber]);

    const sendCode = async () => {
      const regPhoneNumber = /01[0-9]-[0-9]{4}-[0-9]{4}/gi;
      if (!regPhoneNumber.test(phone)) {
        return alert('휴대폰 번호를 확인해주세요.');
      }
      if (shouldCheckExist) {
        try {
          const { data: isExists } = await API.getUserExists({ phone });
          if (isExists) {
            return alert('이미 가입한 번호입니다.');
          }
          const { data: phoneAuthSession } = await API.postPhoneAuthSend(phone);
          setExpiredAt(phoneAuthSession.expireAt);
          setCodeSent(true);
        } catch (e) {
          toast.error(e.response.data.message);
        }
      }
    };

    const verify = async () => {
      if (authNumber.length < 6) {
        return alert('여섯 자리의 숫자를 입력해주세요.');
      }
      try {
        const { data: isCorrect } = await API.postPhoneAuthCompare(
          phone,
          +authNumber
        );
        if (!isCorrect) {
          return alert('인증 번호가 틀립니다.');
        }
        setVerified(true);
        setVerifiedNumber(phone);
        onVerified(phone);
        getLibroUser(phone);
      } catch (error) {
        if (!axios.isAxiosError(error)) return;
        toast.error(error?.response?.data?.message);
      }
    };

    const getLibroUser = async (phone: string) => {
      // 회원가입 / 내정보수정 페이지에서 리브로 유저 데이터 연동
      if (!onGetLibroUser) return;
      try {
        const { data: libroUserInfo } = await API.getLibroUser(phone);
        onGetLibroUser(libroUserInfo);
      } catch (error) {
        if (!axios.isAxiosError(error)) return;
        toast.error(error?.response?.data?.message);
      }
    };

    const isBeforeExpireAt = minutes > 0 || seconds > 0;

    // 수정 페이지에서 전화번호가 사용자 데이터와 달라지면 수정 상태로 변경
    const isEditting = !!initialValue && phone !== initialValue;

    useEffect(() => {
      if (!onEdit) return;
      onEdit(isEditting);
    }, [isEditting, onEdit]);

    return (
      <>
        <div ref={ref} className="mb-4 grid grid-cols-3 gap-3">
          <TextField
            type="tel"
            maxLength={4}
            value={number1}
            onChange={(e) => setNumber1(rmSpace(e.target.value))}
          />
          <TextField
            type="tel"
            maxLength={4}
            value={number2}
            onChange={(e) => setNumber2(rmSpace(e.target.value))}
          />
          <TextField
            type="tel"
            maxLength={4}
            value={number3}
            onChange={(e) => setNumber3(rmSpace(e.target.value))}
          />
        </div>

        {(!initialValue || isEditting) && (
          <div className="flex flex-col space-y-5">
            <Button
              type="button"
              text={`${codeSent ? '인증번호 재전송' : '인증번호 전송'} `}
              onClick={() => sendCode()}
              className="outlined-gray-900"
            />
            {codeSent && (
              <div className="grid grid-cols-5 gap-x-4">
                <div className="col-span-3">
                  <div className="relative">
                    <TextField
                      peerStyle={false}
                      className=""
                      placeholder="인증번호"
                      value={authNumber}
                      maxLength={6}
                      onChange={(e) => setAuthNumber(e.target.value)}
                      helper={
                        !isBeforeExpireAt
                          ? '인증 시간을 초과했습니다. 인증번호를 재전송 해주세요.'
                          : ''
                      }
                    />
                    <div className="absolute top-4 right-4 flex items-center">
                      {!verified
                        ? `${minutes}:${String(seconds).padStart(2, '0')}`
                        : ''}
                    </div>
                  </div>

                  {verified && (
                    <p className="mt-3 text-14 text-brand-2">
                      * 인증이 완료되었습니다.
                    </p>
                  )}
                </div>
                <Button
                  type="button"
                  disabled={!isBeforeExpireAt}
                  onClick={() => verify()}
                  text="확인"
                  className="filled-gray-900 col-span-2 px-12"
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);
