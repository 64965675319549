import base from './base';
import { CalculateOrderResponse, Order } from '../types/orders';
import {
  CalculateNonMemberOrderRequest,
  CreateNonMemberOrder,
  GetNonMemberOrderRequest,
} from '../types/nonMemberOrders';

const nonMemberOrders = {
  postOrder(body: CreateNonMemberOrder) {
    return base.post<Order>('/non-member/orders', body);
  },
  postOrderCalculate(body: CalculateNonMemberOrderRequest) {
    return base.post<CalculateOrderResponse>(
      '/non-member/orders/calculate',
      body
    );
  },
  getOrder(body: GetNonMemberOrderRequest) {
    return base.get<Order>(`/non-member/orders/${body.code}`, {
      params: {
        receiverName: body.receiverName,
        receiverTel: body.receiverTel,
      },
    });
  },
  cancelOrder(body: GetNonMemberOrderRequest) {
    const { code, receiverName, receiverTel } = body;
    return base.post(`/non-member/orders/${code}/cancel`, {
      receiverName,
      receiverTel,
    });
  },
};

export default nonMemberOrders;
