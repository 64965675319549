import React, { FC, HTMLAttributes } from 'react';
import { SectionDto, SectionType } from '../types/sections';
import { SingleCardSection } from '../pages/home/SingleCardSection';
import { MultiCardSection } from '../pages/home/MultiCardSection';
import { GridCardSection } from '../pages/home/GridCardSection';
import { KeywordSection } from '../pages/home/KeywordSection';
import { BannerSection } from '../pages/home/BannerSection';

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {}

export const Section: FC<SectionProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <section className={`${className}`} {...props}>
      {children}
    </section>
  );
};

export const sectionComponent = {
  [SectionType.SINGLE_CARD_SECTION]: (section: SectionDto) => (
    <SingleCardSection key={section.id} section={section} />
  ),
  [SectionType.MULTI_CARD_SECTION_1]: (section: SectionDto) => (
    <MultiCardSection key={section.id} section={section} />
  ),
  [SectionType.MULTI_CARD_SECTION_2]: (section: SectionDto) => (
    <MultiCardSection key={section.id} section={section} />
  ),
  [SectionType.GRID_CARD_SECTION_1]: (section: SectionDto) => (
    <GridCardSection key={section.id} section={section} />
  ),
  [SectionType.GRID_CARD_SECTION_2]: (section: SectionDto) => (
    <GridCardSection key={section.id} section={section} />
  ),
  [SectionType.KEYWORD_SECTION]: (section: SectionDto) => (
    <KeywordSection key={section.id} section={section} />
  ),
  [SectionType.BANNER_SECTION]: (section: SectionDto) => (
    <BannerSection key={section.id} section={section} />
  ),
};
