import { Company } from './companies';
import { ProductType } from './products';
import { CouponDto } from './coupons';
import { User } from './user';

export const PayMethod = {
  FREE_ORDER: 'FREE_ORDER',
  CREDIT_CARD: 'CREDIT_CARD',
  NAVER_PAY: 'NAVER_PAY',
  KAKAO_PAY: 'KAKAO_PAY',
  BANK_TRANSFER: 'BANK_TRANSFER',
} as const;

export const ShippingStatus = {
  INIT: 'INIT',
  READY: 'READY',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISH: 'FINISH',
  CANCEL: 'CANCEL',
  CHANGE: 'CHANGE',
  RETURN: 'RETURN',
  REJECT: 'REJECT',
};

export interface CreateOrder {
  shippingAddressId: number;
  itemIds: number[];
  payMethod: keyof typeof PayMethod;
  userCouponIds: number[];
  usedCouponDiscountAmount: number;
  usedPointAmount: number;
  totalProductPrice: number;
  totalDiscountAmount: number;
  shippingPrice: number;
  totalPayAmount: number;
  isCheckoutAgreed: boolean;
  isProvidePrivacyAgreed?: boolean;
}

export interface Payment {
  id: number;
  code?: string;
  orderId: number;
  orderCode: string;
  method: keyof typeof PayMethod;
  totalProductPrice: number;
  totalDiscountAmount: number;
  shippingPrice: number;
  usedPointAmount: number;
  totalPayAmount: number;
  receivablePointAmount: number;
  requestedAt: string;
  confirmedAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface ShippingLineItem {
  id: number;
  shippingLineId: number;
  productType: ProductType;
  productId: number;
  productName: string;
  productImages: string[];
  unitPrice: number;
  quantity: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
}

export interface ShippingLine {
  id: number;
  orderId: number;
  orderCode: string;
  shippingStatus: keyof typeof ShippingStatus;
  courier?: keyof typeof Courier;
  waybillCode?: string;
  adminMemo?: string;
  addressId: number;
  receiverName: string;
  receiverTel: string;
  zipcode: string;
  address: string;
  addressDetail: string;
  publicDoorPassword: string;
  requestMemo: string;
  companyId: number;
  company: Company;
  items: ShippingLineItem[];
  createdAt: string;
  updatedAt: string;
  branchCode: string;
}

export interface Order {
  id: number;
  code: string;
  email?: string;
  branchCode: string;
  title: string;
  image: string;
  userId: number;
  user: User;
  paidCoupons: CouponDto[];
  payment: Payment;
  shippingLines: ShippingLine[];
  createdAt: string;
  updatedAt: string;
  orderedAt: string;
}

export interface CalculateOrderRequest {
  itemIds: number[];
  userCouponIds: number[];
  usedPointAmount: number;
  shippingPrice: number;
}

export interface CalculateOrderResponse {
  itemIds: number[];
  userCouponIds: number[];
  usedCouponDiscountAmount: number;
  usedPointAmount: number;
  totalDiscountAmount: number;
  shippingPrice: number;
  totalProductPrice: number;
  totalPayAmount: number;
  receivablePointAmount: number;
}

// NOTE: 현재 널리 사용하는 배송업체만 등록되어 있으므로 필요 시 추가
export const Courier = {
  HYUNDAI: 'HYUNDAI',
  KGB: 'KGB',
  EPOST: 'EPOST',
  HANJIN: 'HANJIN',
  CJGLS: 'CJGLS',
  KDEXP: 'KDEXP',
  DIRECT: 'DIRECT',
  ILYANG: 'ILYANG',
  CHUNIL: 'CHUNIL',
  CSLOGIS: 'CSLOGIS',
  DAESIN: 'DAESIN',
  HDEXP: 'HDEXP',
  DHL: 'DHL',
  TNT: 'TNT',
  HONAM: 'HONAM',
  KUNYOUNG: 'KUNYOUNG',
};
