// @ts-ignore

import BootPay from 'bootpay-js';

import { Order, Payment, PayMethod, ShippingLineItem } from '../types/orders';
import moment from 'moment/moment';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { User } from '../types/user';
import nonMemberCarts from '../api/nonMemberCarts';
const bootPayMethod = {
  [PayMethod.CREDIT_CARD]: 'card',
  [PayMethod.BANK_TRANSFER]: 'bank',
  [PayMethod.NAVER_PAY]: 'npay',
  [PayMethod.KAKAO_PAY]: 'kakao',
  [PayMethod.FREE_ORDER]: 'free',
};

const logOrderToGA = (order: Order) => {
  if (process.env.REACT_APP_SERVER_ENV !== 'operation') return;
  if (!order) return;
  // @ts-ignore
  gtag('event', 'purchase', {
    transaction_id: order.code,
    value: order.payment.totalProductPrice,
    shipping: order.payment.shippingPrice,
    currency: 'KRW',
    items: order.shippingLines.flatMap((shippingLine) => {
      return shippingLine.items.map((item) => ({
        item_id: item.productId,
        item_name: item.productName,
        currency: 'KRW',
        item_category: item.productType,
        price: item.totalPrice,
        quantity: item.quantity,
      }));
    }),
  });
};

export const useCompleteOrder = () => {
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const completeOrder = async (order: Order) => {
    logOrderToGA(order);

    const cartPayItemIdsStr = sessionStorage.getItem('cartPayItemIds');
    const cartPayItemIds = JSON.parse(cartPayItemIdsStr!);

    nonMemberCarts.removeOrderItems(cartPayItemIds).then(async () => {
      await queryClient.invalidateQueries(['cartShallow']);
      await queryClient.invalidateQueries(['cart']);
      push('/cart/pay/success', order);
    });
  };

  return { completeOrder };
};
export const useExecuteBootpay = () => {
  const { completeOrder } = useCompleteOrder();

  const executeBootpay = (
    order: Order,
    payment: Payment,
    items: ShippingLineItem[],
    user: User | Partial<User>
  ) => {
    const bootPayRequestBody = {
      price: payment.totalPayAmount,
      application_id: process.env.REACT_APP_BOOTPAY_APP_ID,
      name: items
        .map((item) => `${item.productName}[${item.quantity}]`)
        .join(', '),
      pg: 'payapp',
      method: bootPayMethod[payment.method],
      show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
      items: items.map((item) => ({
        item_name: item.productName,
        qty: item.quantity,
        unique: item.productId.toString(),
        price: item.unitPrice,
        cat1: item.productType,
      })),
      user_info: {
        username: user.name,
        email: user.email,
        addr: `${user.address} ${user.addressDetail}`,
        phone: user.phone,
      },
      order_id: order.id,
      account_expire_at: moment().add(7, 'days').format('YYYY-MM-DD'),
      // extra: {
      //   quota: '0,2,3', // 결제금액이 5만원 이상시 할부개월 허용범위를 설정할 수 있음, [0(일시불), 2개월, 3개월] 허용, 미설정시 12개월까지 허용,
      // }
    };

    BootPay.request(bootPayRequestBody)
      .error(function (data: unknown) {
        //결제 진행시 에러가 발생하면 수행됩니다.
      })
      .cancel(function (data: unknown) {
        //결제가 취소되면 수행됩니다.
      })
      .ready(function (data: unknown) {
        // 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니
      })
      .confirm(function (data: unknown) {
        //결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
        //주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
        var enable = true; // 재고 수량 관리 로직 혹은 다른 처리
        if (enable) {
          BootPay.transactionConfirm(data); // 조건이 맞으면 승인 처리를 한다.
        } else {
          BootPay.removePaymentWindow(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
        }
      })
      .close(function (data: unknown) {
        // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
      })
      .done(async function (data: unknown) {
        // BootPay 결제 완료 처리 (https://docs.bootpay.co.kr/flow 7번)
        // @ts-ignore
        completeOrder(order);
      });
  };

  return { executeBootpay };
};
