import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import { MomentFormat, utcToLocalFormat } from '../../../../plugins/moment';
import { Order, PayMethod } from '../../../../types/orders';
import { toast } from 'react-toastify';

export const CartPaySuccessPage = () => {
  const { push, location } = useHistory<Order>();

  const { state } = location;
  const payMethod = useMemo(() => {
    if (!state.payment?.method) return '';
    switch (state.payment.method) {
      case PayMethod.BANK_TRANSFER:
        return '계좌이체';
      case PayMethod.CREDIT_CARD:
        return '신용카드';
      case PayMethod.KAKAO_PAY:
        return '카카오페이';
      case PayMethod.NAVER_PAY:
        return '네이버페이';
    }
  }, [state.payment?.method]);
  if (!state.id) return <></>;
  return (
    <div className="p-5 md:mx-auto md:mt-14 md:w-full md:max-w-lg">
      <h4 className="mb-4 font-medium">주문완료</h4>

      <div className="card-2 space-y-2">
        <div className="mb-4 flex items-center justify-between border-b border-gray-100 pb-4">
          <h6 className="font-medium">총 결제금액</h6>
          <h6 className="font-bold">
            ₩{state.payment.totalPayAmount.toLocaleString()}
          </h6>
        </div>

        <div className="flex items-center justify-between">
          <span className="">주문일시</span>
          <span className="text-gray-500">
            {utcToLocalFormat(state.payment.updatedAt, MomentFormat.DateTime)}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="">주문번호</span>
          <button className="flex items-center space-x-2 text-gray-500">
            <span>{state.code}</span>{' '}
            <Icon.Copy
              onClick={() => {
                navigator.clipboard.writeText(state.code);
                toast('주문번호가 복사되었습니다.', {
                  type: 'success',
                });
              }}
            />
          </button>
        </div>
        <div className="flex items-center justify-between">
          <span className="mr-12">상품</span>

          <span className="flex-1 text-right text-gray-500 line-clamp-1">
            {state.title}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="">결제수단</span>
          <span className="text-gray-500">{payMethod}</span>
        </div>
      </div>

      <Button
        onClick={() => push('/')}
        text="계속 쇼핑하기"
        className="filled-gray-900 mt-12 w-full"
      />
    </div>
  );
};
