import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../api';
import {
  CalculateOrderRequest,
  CalculateOrderResponse,
  CreateOrder,
  Order,
  ShippingLineItem,
} from '../types/orders';
import { useUser } from './user';
import { useAuth } from '../hooks';
import nonMemberOrders from '../api/nonMemberOrders';
import { useCompleteOrder, useExecuteBootpay } from '../hooks/useBootpay';

export const useOrders = () => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['orders'],
    async ({ pageParam = 1 }) => API.getOrders(pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        const pagination = lastPage.data.pagination;
        if (pagination.currentPage < pagination.totalPage)
          return pagination.currentPage + 1;
        return undefined;
      },
    }
  );

  return {
    orders: data?.pages.flatMap((page) => page?.data.items) ?? [],
    totalItemCount: data?.pages[0]?.data.pagination.totalItemCount,
    isLoading,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  };
};

export const useLastOrder = () => {
  const { data, isLoading } = useQuery(['lastOrder'], () => API.getOrders(1));

  if (isLoading || !data?.data || data.data.items.length === 0) {
    return {
      order: {} as Order,
    };
  }

  return {
    order: data?.data.items[0],
  };
};

export const useOrderDetail = (id: number) => {
  const { data, isLoading } = useQuery(['orders', id], () => API.getOrder(id), {
    select: useCallback((data) => {
      const order: Order = data.data;
      const books = order.shippingLines.filter((line) =>
        line.items.some((item) => item.productType === 'BOOK')
      );
      const generals = order.shippingLines.filter((line) =>
        line.items.some((item) => item.productType === 'GENERAL')
      );
      return {
        order,
        books,
        generals,
      };
    }, []),
  });

  if (isLoading || !data?.order) {
    return {
      order: {} as Order,
      books: [],
      generals: [],
    };
  }

  return {
    ...data,
  };
};

export const useStartOrder = () => {
  const { user } = useUser();
  const { executeBootpay } = useExecuteBootpay();
  const { completeOrder } = useCompleteOrder();
  const { mutate: startOrder } = useMutation(
    (body: CreateOrder) => API.postOrder(body),
    {
      onSuccess: async (res) => {
        // BootPay 결제 요청 (https://docs.bootpay.co.kr/flow 1번)
        const order = res.data;
        const { payment } = order;
        const isFreeByMileage = payment.totalPayAmount === 0;

        const items: ShippingLineItem[] = [];
        order.shippingLines.forEach((shippingLine) => {
          shippingLine.items.forEach((item) => {
            items.push(item);
          });
        });

        if (isFreeByMileage) {
          completeOrder(order);
        } else {
          executeBootpay(order, payment, items, user);
        }
      },
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
  return {
    startOrder,
  };
};

export const useOrderCalculate = (body: CalculateOrderRequest) => {
  const { authenticated } = useAuth();
  const { data, isLoading, refetch } = useQuery(
    [
      'orderCalculate',
      body.itemIds,
      body.usedPointAmount,
      body.userCouponIds,
      body.shippingPrice,
    ],
    async () => {
      if (authenticated) {
        return await API.postOrderCalculate(body);
      } else {
        const { usedPointAmount, userCouponIds, ...rest } = body;
        return await nonMemberOrders.postOrderCalculate(rest);
      }
    },
    {
      enabled: body.itemIds.length > 0,
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
      refetchOnReconnect: 'always',
    }
  );
  if (isLoading || !data?.data) {
    return {
      calculrateResult: {} as CalculateOrderResponse,
    };
  }

  return {
    calculrateResult: data.data,
    refetch,
  };
};

export const useCancelOrder = () => {
  const { push } = useHistory();
  const { mutate: cancelOrder } = useMutation(
    (id: string) => API.postOrderCancel(id),
    {
      onSuccess: () => {
        toast.success('주문이 취소되었습니다.');
        push('/order-history');
      },
    }
  );
  return {
    cancelOrder,
  };
};
