import { SectionDto } from '../types/sections'
import base from './base'

const sections = {
  getSection (id: string) {
    return base.get<SectionDto>(`sections/${id}`)
  },
  getSections (position: "MAIN" | "LIFE") {
    return base.get<SectionDto[]>('sections', {
        params: {
            position
        }
    })
  },
}

export default sections
