import { Paginated } from '../types/api';
import { Category, RecommendedCategory } from '../types/categories';
import base from './base';
import { TabType } from '../enums';

const categories = {
  getCategories(type: TabType) {
    return base.get<Paginated<Category>>('/categories', {
      params: {
        type,
        'where[categoryId]': 'null',
        itemsPerPage: 50,
      },
    });
  },
  getCategory(id: number) {
    return base.get<Category>(`/categories/${id.toString()}`);
  },
  getRecommendedCategories() {
    return base.get<Paginated<RecommendedCategory>>('/recommended-categories');
  },
};

export default categories;
