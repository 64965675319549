import React, { ChangeEvent } from 'react';
import { ProductCard } from '../../../components/ProductCard';
import { Select } from '../../../components/Select';
import { TopBar } from '../../../components/TopBar';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useParams } from 'react-router-dom';
import { KeywordParams } from '../../../types/router';
import { useProductsSearch } from '../../../queries/products';
import qs from 'qs';
import { KeywordButton } from '../../../components/KeywordButton';

const searchTypes = [
  {
    id: 'all',
    title: '전체',
  },
  {
    id: 'book',
    title: '도서',
  },
  {
    id: 'general',
    title: '라이프',
  },
];

export const SearchResultsPage = () => {
  const { keyword } = useParams<KeywordParams>();
  const { replace, location } = useHistory();
  const queryParams = qs.parse(location.search.replace('?', ''));
  const sortBy = (queryParams.sortBy as string) ?? 'rank';
  const type = (queryParams.type as 'all' | 'book' | 'general') ?? 'all';
  const { products, fetchNextPage, hasNextPage, totalItemCount, isLoading } =
    useProductsSearch(keyword, sortBy, type);

  const changeSortBy = (e: ChangeEvent<HTMLSelectElement>) => {
    replace({
      search: qs.stringify({
        ...queryParams,
        sortBy: e.target.value,
      }),
    });
  };

  // TODO: queryString 에 맞춰서 검색되게끔 react-query 수정 필요
  const handleType = (e: any) => {
    replace({
      search: qs.stringify({
        ...queryParams,
        type: e.target.name,
      }),
    });
  };

  return (
    <div className="mx-auto mt-8 w-screen md:max-w-screen-xl md:px-5 xl:px-0">
      <TopBar title={`"${keyword}"`} />
      <div className={'mt-4 flex'}>
        {searchTypes.map((searchType, index) => (
          <div key={searchType.id}>
            <KeywordButton
              title={searchType.title}
              name={searchType.id}
              onClick={handleType}
              selected={
                index === 0
                  ? type === null || searchType.id === type
                  : searchType.id === type
              }
            />
          </div>
        ))}
      </div>
      {Number(totalItemCount) !== 0 ? (
        <div className="px-5 md:px-0">
          <div className="flex items-center justify-between">
            <Select
              className="border-0"
              onChange={changeSortBy}
              defaultValue={sortBy}
            >
              <option value="rank">추천순</option>
              <option value="recent">최신순</option>
              <option value="popular">인기순</option>
            </Select>
            <span className="text-12 text-gray-500">
              총 {totalItemCount?.toLocaleString()}건
            </span>
          </div>
          {isLoading && (
            <div className="mt-[18vw] w-full break-words px-[20vw] text-center font-semibold text-gray-500">
              로딩 중입니다.
            </div>
          )}
          <InfiniteScroll
            dataLength={products?.length ?? 0}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<>로딩 중입니다.</>}
            className="grid grid-cols-2 gap-5 md:grid-cols-6"
          >
            {products?.map((p) => (
              <ProductCard to={`/product/${p.id}`} product={p} key={p.id} />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="mt-[18vw] w-full break-words px-[20vw] text-center font-semibold text-gray-500">
          검색 결과가 없습니다. <br />
          다른 검색어를 입력하거나 두 단어 이상의 검색어인 경우 <br />
          띄어쓰기를 포함하여 검색해주세요.
        </div>
      )}
    </div>
  );
};
