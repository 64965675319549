import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Product, ProductType } from '../types/products';
import { toast } from 'react-toastify';
import API from '../api';
import { useHistory } from 'react-router-dom';
import nonMemberCarts from '../api/nonMemberCarts';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Cart, CartItem, ChangeCartItemAmount } from '../types/cart';
import { filterCartItemsByType } from '../utils';

export const useNonCart = () => {
  const { isLoading, data, refetch } = useQuery(
    'non-member-cart',
    async () => {
      const res = await nonMemberCarts.getCart();
      return res;
    },
    {
      select: useCallback((data: AxiosResponse<Cart> | undefined) => {
        const cart = data?.data;
        if (!cart) return;

        const onlineItems = cart.items.filter(
          (item) => item.branchCode === '-'
        );
        const online = {
          books: filterCartItemsByType(onlineItems, 'BOOK'),
          generals: filterCartItemsByType(onlineItems, 'GENERAL'),
          count: onlineItems.length,
        };

        const pickupItems = cart.items.filter(
          (item) => item.branchCode !== '-'
        );
        const pickup = {
          books: filterCartItemsByType(pickupItems, 'BOOK'),
          generals: filterCartItemsByType(pickupItems, 'GENERAL'),
          count: pickupItems.length,
          currentBranchCode:
            pickupItems.length > 0 ? pickupItems[0].branchCode : '',
        };

        return {
          cart: cart.items,
          online,
          pickup,
        };
      }, []),
    }
  );
  if (isLoading || !data) {
    return {
      cart: [],
      online: {
        books: [],
        generals: [],
        count: 0,
      },
      pickup: {
        books: [],
        generals: [],
        count: 0,
        currentBranchCode: '',
      },
    };
  }
  return {
    ...data,
    refetch,
  };
};

interface ChangeCartItemAmountParam {
  product: Product;
  branchCode?: string;
  cartQuantity?: number;
}
export const useAddProductToNonCart = (blockExisted = true) => {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  // const { cart } = useNonCart();
  const { mutateAsync: addProductToNonCart } = useMutation(
    async ({
      product,
      cartQuantity = 1,
      branchCode,
    }: ChangeCartItemAmountParam) => {
      if (
        product.type === 'BOOK' &&
        (!branchCode || branchCode === '-') &&
        product.remainQuantity < cartQuantity
      ) {
        toast.info('재고가 부족합니다.');
        return Promise.reject();
      }
      if (product.type === 'GENERAL' && product.isSoldOut) {
        toast.info('재고가 부족합니다.');
        return Promise.reject();
      }
      // const isExist = cart.items.some((item) => item.productId === product.id);
      // if (blockExisted && isExist) {
      //   toast.info('이미 추가된 상품입니다.', {
      //     onClick: () => {
      //       push('/cart');
      //     },
      //   });
      //   return Promise.reject();
      // }
      return await nonMemberCarts.postCartItems(product.id, {
        quantity: cartQuantity,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cartShallow']);
        await queryClient.refetchQueries(['cart']);
        if (blockExisted) {
          toast.success('상품이 장바구니에 추가되었습니다.', {
            onClick: () => {
              push('/cart');
            },
          });
        }
      },
    }
  );
  return {
    addProductToNonCart,
  };
};

export const useRemoveProductToNonCart = () => {
  const queryClient = useQueryClient();
  const { mutate: removeProductToNonCart } = useMutation(
    ({ product, cartQuantity = 1 }: ChangeCartItemAmountParam) =>
      nonMemberCarts.deleteCartItems(product.id, {
        quantity: cartQuantity,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cartShallow']);
        await queryClient.refetchQueries(['cart']);
      },
    }
  );
  return {
    removeProductToNonCart,
  };
};
