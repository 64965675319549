import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import API from '../api';
import { SectionDto } from '../types/sections';

export const useSections = (position: 'MAIN' | 'LIFE') => {
  const { data, isLoading } = useQuery(
    ['sections', position],
    async () => await API.getSections(position),
    {
      staleTime: 120_000,
      select: useCallback((data: AxiosResponse<SectionDto[]>) => {
        const sections = data.data;
        if (!sections) return;
        const filteredSections = sections.filter((section) => section.isShow);
        return {
          sections: filteredSections
            .sort((a, b) => a.order - b.order)
            .map((section) => ({
              ...section,
              products: section.products
                .filter((product) => !product.product.isDisplayHidden)
                .sort((a, b) => a.order - b.order),
            })),
        };
      }, []),
    }
  );
  if (isLoading || !data) {
    return {
      sections: [],
    };
  }

  return {
    ...data,
  };
};

export const useSection = (id: string) => {
  const { data, isLoading } = useQuery(
    ['section', id],
    async () => await API.getSection(id)
  );
  if (isLoading) {
    return {
      section: {} as SectionDto,
    };
  }

  return {
    section: data?.data,
  };
};
