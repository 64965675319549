import React, { useState } from 'react';
import { Button } from '../../../components/Button';
import { TopBar } from '../../../components/TopBar';
import { ProductList } from '../../../components/cart/ProductList';
import { PaymentType } from '../../../components/cart/PaymentType';
import { Checkbox } from '../../../components/Checkbox';
import { Link } from 'react-router-dom';
import { NonMemberAddress } from '../../../components/cart/NonMemberAddress';
import { PayMethod } from '../../../types/orders';

export const CartNonMemberPage = () => {
  const [payMethod, setPayMethod] = useState<keyof typeof PayMethod>(
    PayMethod.CREDIT_CARD
  );
  return (
    <div className="md:mx-auto md:w-full md:max-w-screen-xl">
      {/*<TopBar title="결제하기" />*/}

      {/*<div className="p-5 md:flex md:space-x-8 md:px-0">*/}
      {/*  <div className="flex-1 space-y-8 ">*/}
      {/*    <NonMemberAddress />*/}

      {/*    <ProductList />*/}

      {/*    <PaymentType payMethod={payMethod} setPayMethod={setPayMethod} />*/}

      {/*    <div className="flex space-x-4">*/}
      {/*      <Checkbox />*/}
      {/*      <Link to="https://www.google.com" className="">*/}
      {/*        개인정보 수집 및 이용동의 (필수)*/}
      {/*      </Link>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="md:card-2 mt-8 h-full space-y-3 text-14 md:mt-0 md:w-1/3">*/}
      {/*    <h6 className="hidden font-bold md:block">결제 금액</h6>*/}
      {/*    <div className="flex justify-between">*/}
      {/*      <span className="text-gray-500">총 주문금액</span>*/}
      {/*      <span className="">0원</span>*/}
      {/*    </div>*/}
      {/*    <div className="flex justify-between">*/}
      {/*      <span className="text-gray-500">총 할인금액</span>*/}
      {/*      <span className="">-0원</span>*/}
      {/*    </div>*/}
      {/*    <div className="flex justify-between">*/}
      {/*      <span className="text-gray-500">배송비</span>*/}
      {/*      <span className="">0원</span>*/}
      {/*    </div>*/}

      {/*    <hr className="border-gray-100" />*/}

      {/*    <div className="flex justify-between text-17 font-bold">*/}
      {/*      <span className="">총 결제금액</span>*/}
      {/*      <span className="">0원</span>*/}
      {/*    </div>*/}

      {/*    <div className="pt-12">*/}
      {/*      <Button*/}
      {/*        // disabled*/}
      {/*        to="/cart/non-member/success"*/}
      {/*        text="결제하기"*/}
      {/*        className="filled-gray-900 w-full"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
