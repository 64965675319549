import React, { useEffect, useMemo, useState } from 'react';
import { TopBar } from '../../../components/TopBar';
import { useAuth } from '../../../hooks';
import { ShippingAddressModal } from '../../../components/modal/ShippingAddressModal';
import { Icon } from '../../../components/Icon';
import { NonMemberAddress } from '../../../components/cart/NonMemberAddress';
import { ProductList } from '../../../components/cart/ProductList';
import { PaymentType } from '../../../components/cart/PaymentType';
import { CouponSelector } from '../../../components/cart/CouponSelector';
import { Points } from '../../../components/cart/Points';
import { Checkbox } from '../../../components/Checkbox';
import { TotalAmount } from '../../../components/cart/TotalAmount';
import { Button } from '../../../components/Button';
import { useUser } from '../../../queries/user';
import { useShippingAddresses } from '../../../queries/shippingAddress';
import { useOrderCalculate, useStartOrder } from '../../../queries/orders';
import { useCart } from '../../../queries/cart';
import { useForm } from 'react-hook-form';
import { NonMemberAddressForm } from '../../../types';
import { CreateOrder, PayMethod } from '../../../types/orders';
import { toast } from 'react-toastify';
import { useShipmentsPricingByProducts } from '../../../queries/shipments';
import { ShippingAddress } from '../../../types/shippingAddress';
import { useNonMemberStartOrder } from '../../../queries/nonMemberOrders';
import CONST from '../../../const';
/**
 * TODO:
 *  - 장바구니 데이터를 불러와서 주문상품 데이터 매칭, ProductList
 *  - 비회원 정보 form 추가, NonMemberAddress
 *     - 주소 입력 모달 추가
 *     - validation ?
 */
const isRightAddress = (
  address: ShippingAddress | undefined,
  authenticated: boolean
) => {
  if (!authenticated) return true;
  if (!address || address?.addressDetail === '') {
    !toast.isActive(1) &&
      toast('배송지 정보가 잘못된 것 같아요. 상세 주소를 확인해주세요.', {
        toastId: 1,
      });
    return false;
  } else {
    return true;
  }
};

export const CartPayPage = () => {
  const { user } = useUser();
  const { authenticated } = useAuth();

  const { shippingAddresses } = useShippingAddresses();
  const { startOrder } = useStartOrder();
  const { startNonMemberOrder } = useNonMemberStartOrder();
  const { cart } = useCart();

  const form = useForm<NonMemberAddressForm>();

  const [showShippingAddressModal, setShowShippingAddressModal] =
    useState(false);
  const [payMethod, setPayMethod] = useState<keyof typeof PayMethod>(
    PayMethod.CREDIT_CARD
  );
  const [shippingAddressId, setShippingAddressId] = useState(0);

  const [usedPointAmount, setUsedPointAmount] = useState(0);
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(null);

  const [agreeOrder, setAgreeOrder] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);

  const address = useMemo(() => {
    return shippingAddresses.find(
      (address) => address.id === shippingAddressId
    );
  }, [shippingAddresses, shippingAddressId]);

  const orderItems = useMemo(() => {
    const cartPayItemIdsStr = sessionStorage.getItem('cartPayItemIds');
    if (!cartPayItemIdsStr) return [];
    const cartPayItemIds = JSON.parse(cartPayItemIdsStr);
    return cart.filter((item) => cartPayItemIds.includes(item.id));
  }, [cart]);
  const { calculrateResult } = useShipmentsPricingByProducts(false, orderItems);

  const { calculrateResult: orderCalculateResult } = useOrderCalculate({
    usedPointAmount,
    itemIds: orderItems.map((item) => item.id),
    shippingPrice: calculrateResult.totalShippingPrice,
    userCouponIds: selectedCouponId ? [selectedCouponId] : [],
  });

  const totalProductPrice = useMemo(() => {
    const prices = orderItems.map((item) => item.totalPrice);
    return prices.reduce((total, price) => total + price, 0);
  }, [orderItems]);
  const totalPayAmount = useMemo(() => {
    return (
      totalProductPrice + orderCalculateResult.shippingPrice - usedPointAmount
    );
  }, [totalProductPrice, orderCalculateResult.shippingPrice, usedPointAmount]);

  const isFreeByMileage = totalPayAmount === 0;

  const MIMINUM_PAY_AMOUNT = 1000;
  const isOrderable =
    agreeOrder &&
    agreePrivacy &&
    isRightAddress(address, authenticated) &&
    (totalPayAmount >= MIMINUM_PAY_AMOUNT || isFreeByMileage);

  const startPay = async () => {
    const body: CreateOrder = {
      isCheckoutAgreed: true,
      itemIds: orderItems.map((item) => item.id),
      payMethod: isFreeByMileage ? PayMethod.FREE_ORDER : payMethod,
      shippingAddressId,
      shippingPrice: orderCalculateResult.shippingPrice,
      totalDiscountAmount: orderCalculateResult.totalDiscountAmount,
      totalPayAmount: orderCalculateResult.totalPayAmount,
      totalProductPrice: orderCalculateResult.totalProductPrice,
      usedPointAmount: orderCalculateResult.usedPointAmount,
      isProvidePrivacyAgreed: true,
      userCouponIds: selectedCouponId ? [selectedCouponId] : [],
      usedCouponDiscountAmount: orderCalculateResult.totalDiscountAmount,
    };
    startOrder(body);
  };

  const onSubmit = (data: NonMemberAddressForm) => {
    const body = {
      ...data,
      itemIds: orderItems.map((item) => item.id),
      payMethod: isFreeByMileage ? PayMethod.FREE_ORDER : payMethod,
      receiverTel: data.phone,
      isCheckoutAgreed: true,
      isProvidePrivacyAgreed: true,
      totalProductPrice: orderCalculateResult.totalProductPrice,
      totalPayAmount: orderCalculateResult.totalPayAmount,
      totalDiscountAmount: orderCalculateResult.totalDiscountAmount ?? 0,
      shippingPrice: orderCalculateResult.shippingPrice,
    };
    startNonMemberOrder(body);
  };

  const openAddressModal = () => {
    setShowShippingAddressModal(true);
  };

  useEffect(() => {
    if (!user) return;
    setShippingAddressId(user.defaultShippingAddressId ?? 0);
  }, [user]);

  useEffect(() => {
    if (selectedCouponId) setUsedPointAmount(0);
  }, [selectedCouponId]);

  useEffect(() => {
    if (usedPointAmount > 0) setSelectedCouponId(null);
  }, [usedPointAmount]);
  return (
    <>
      <div className="mt-10 max-w-screen-xl md:mx-auto md:w-full">
        <TopBar title="결제하기" />
        <div>
          <ShippingAddressModal
            open={showShippingAddressModal}
            onClose={() => setShowShippingAddressModal(false)}
            selectedAddressId={shippingAddressId}
            onCheckAddress={setShippingAddressId}
          />
          <div className="flex flex-col p-5 md:flex-row md:space-x-8 md:p-0 md:py-6">
            <div className="flex flex-1 flex-col gap-8">
              {authenticated ? (
                <>
                  {address ? (
                    <>
                      <div className="card-2">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center justify-between space-x-3">
                            <h6 className="font-bold">{address.title}</h6>

                            {address.isDefault && (
                              <div className="rounded-full border-2 border-brand-2 px-2 py-1 text-14 font-bold text-brand-2">
                                기본배송지
                              </div>
                            )}
                          </div>

                          <button
                            onClick={openAddressModal}
                            className="text-13 text-gray-600"
                          >
                            변경
                          </button>
                        </div>

                        <p className="pt-4 text-13 text-gray-600">
                          {`${address.address} ${address.addressDetail}`} <br />
                          {`${address.publicDoorPassword} | ${address.receiverName} | ${address.receiverTel}`}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        onClick={openAddressModal}
                        className="flex cursor-pointer justify-center space-x-2 rounded-xl bg-gray-100 p-5 text-gray-600"
                      >
                        <span className="">주소지 추가하기</span>
                        <Icon.PlusCircle />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <NonMemberAddress form={form} />
              )}

              <ProductList orderItems={orderItems} />

              <PaymentType payMethod={payMethod} setPayMethod={setPayMethod} />

              {authenticated && (
                <div className={'card-2'}>
                  <h6 className="mb-4 font-bold">할인</h6>
                  <CouponSelector
                    totalProductPrice={totalProductPrice}
                    totalDeliveryPrice={orderCalculateResult.shippingPrice}
                    orderItems={orderItems}
                    // selectedCouponId={selectedCouponId}
                    onSelect={(id) => setSelectedCouponId(id)}
                  />
                  <Points
                    value={usedPointAmount}
                    disabled={selectedCouponId !== null}
                    totalProductPrice={totalProductPrice}
                    shippingPrice={orderCalculateResult.shippingPrice}
                    onChangeMileage={(mileage: number) =>
                      setUsedPointAmount(mileage)
                    }
                  />
                </div>
              )}

              <div className="space-y-4">
                <p className="text-15 text-gray-700">
                  주문하실 상품, 가격, 배송정보, 할인정보 등을 확인 하였으며,
                  구매에 동의하시겠습니까?
                </p>

                <Checkbox
                  label="동의합니다. (전자상거래법 제8조 제2항)"
                  labelClassName={`${
                    agreeOrder
                      ? `text-black font-bold text-16`
                      : `text-gray-500 text-16`
                  }`}
                  onChange={() => setAgreeOrder(!agreeOrder)}
                  checked={agreeOrder}
                />
                <div className="flex space-x-3 ">
                  <Checkbox
                    onChange={() => setAgreePrivacy(!agreePrivacy)}
                    checked={agreePrivacy}
                  />
                  <button
                    onClick={() => {
                      window.open(CONST.PRIVACY_AGREEMENT_URL, '_blank');
                    }}
                    className={`${
                      agreePrivacy
                        ? `text-16 font-bold text-black underline`
                        : `text-16 text-gray-500 underline`
                    }`}
                  >
                    개인정보 수집 및 이용동의
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-8">
              <TotalAmount
                orderCalculateResult={orderCalculateResult}
                isPickUp={false}
              />
              <Button
                disabled={!isOrderable}
                onClick={authenticated ? startPay : form.handleSubmit(onSubmit)}
                text="결제하기"
                className="filled-gray-900 hidden md:block"
              />
            </div>
          </div>
          <div className="fixed bottom-5 w-full px-5 md:hidden">
            <Button
              disabled={!isOrderable}
              onClick={startPay}
              text="결제하기"
              className="filled-gray-900 w-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};
