import { useQuery } from 'react-query';
import API from '../api';
import { Category } from '../types/categories';
import { TabType } from '../enums';

export const useCategories = (type: TabType) => {
  const { data, isLoading } = useQuery(
    'categories',
    async () => await API.getCategories(type)
  );
  if (isLoading) {
    return {
      categories: [],
    };
  }

  return {
    categories: data?.data.items ?? [],
  };
};

export const useCategory = (id: number) => {
  const { data, isLoading } = useQuery(
    ['category', id],
    async () => await API.getCategory(id),
    {
      enabled: id > 0,
    }
  );
  if (isLoading) {
    return {
      category: {} as Category,
    };
  }

  return {
    category: data?.data,
  };
};

export const useRecommendedCategories = () => {
  const { data, isLoading } = useQuery(
    'recommendedCategories',
    async () => await API.getRecommendedCategories()
  );
  if (isLoading) {
    return {
      recommendedCategories: [],
    };
  }

  return {
    recommendedCategories: data?.data.items ?? [],
  };
};
