import { useMemo } from 'react';
import { DeliveryInfo } from './DeliveryInfo';
import { OrderProductCard } from './OrderProductCard';
import { ShippingLine } from '../../types/orders';

interface OrderHistoryProductsProps {
  books: ShippingLine[];
  generals: ShippingLine[];
}

export const OrderHistoryProducts = ({
  books,
  generals,
}: OrderHistoryProductsProps) => {
  const bookCount = useMemo(() => {
    if (books.length === 0) return 0;
    return books
      .flatMap((book) => book.items.map((item) => item.quantity))
      .reduce((count, curr) => count + curr, 0);
  }, [books]);
  const generalCount = useMemo(() => {
    if (generals.length === 0) return 0;
    return generals
      .flatMap((general) => general.items.map((item) => item.quantity))
      .reduce((count, curr) => count + curr, 0);
  }, [generals]);
  return (
    <div className="card-2 w-full overflow-hidden">
      {bookCount > 0 && (
        <>
          <div className="-mx-5 -mt-8 flex items-center justify-between bg-brand-3 p-5">
            <h6 className="font-bold">도서상품</h6>
            <span className="text-15 text-gray-600">총 {bookCount}개</span>
          </div>

          {books.map((book, i) => (
            <div
              key={book.orderId}
              className={`${
                bookCount > 1 && generalCount - i > 1
                  ? 'border-b border-gray-100 pb-5'
                  : ''
              } `}
            >
              <DeliveryInfo shippingLine={book} />
              <div className="space-y-6">
                {book.items.map((item) => (
                  <OrderProductCard
                    shippingStatus={book.shippingStatus}
                    key={`${book.id}-${item.id}`}
                    product={item}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      )}

      {generalCount > 0 && (
        <>
          <div className="-mx-5 mt-8 flex items-center justify-between bg-brand-3 p-5">
            <h6 className="font-bold">일반상품</h6>
            <span className="text-15 text-gray-600">총 {generalCount}개</span>
          </div>

          {generals.map((general, i) => (
            <div
              key={general.orderId}
              className={`${
                generalCount > 1 && generalCount - i > 1
                  ? 'border-b border-gray-100 pb-5'
                  : ''
              } `}
            >
              <DeliveryInfo shippingLine={general} />
              <div className="space-y-6">
                {general.items.map((item) => (
                  <OrderProductCard
                    shippingStatus={general.shippingStatus}
                    key={`${general.id}-${item.id}`}
                    product={item}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
