import React, { FC, useState } from 'react';
import { Button } from '../Button';
import { TextField } from '../TextField';
import { UseFormReturn } from 'react-hook-form';
import { NonMemberAddressForm } from '../../types';
import { DaumPost, DaumPostResult } from '../DaumPost';

interface NonMemberAddressProps {
  form: UseFormReturn<NonMemberAddressForm>;
}

export const NonMemberAddress: FC<NonMemberAddressProps> = ({ form }) => {
  const [openDaumPost, setOpenDaumPost] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = form;
  const onCompleteDaumPost = (data: DaumPostResult) => {
    setValue('address', data.address);
    setValue('zipcode', data.zonecode);
    clearErrors('address');
  };
  return (
    <>
      <div className="card-2 space-y-9">
        <h6 className="font-bold">배송지 입력</h6>

        <TextField
          label="받으시는 분 이름*"
          placeholder="받으시는 분 이름*"
          helper={errors.receiverName?.message}
          {...register('receiverName', {
            required: '이름을 입력해주세요.',
          })}
        />

        <TextField
          label="연락처*"
          placeholder="연락처*"
          helper={errors.phone?.message}
          {...register('phone', {
            required: '연락처를 입력해주세요.',
          })}
        />
        <TextField
          type="email"
          label="이메일*"
          placeholder="이메일*"
          helper={errors.email?.message}
          {...register('email', {
            required: '이메일을 입력해주세요.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '올바른 이메일 형식을 입력해주세요.',
            },
          })}
        />
        <div className="space-y-3">
          <div className="flex space-x-3">
            <div className="w-2/3 md:w-full">
              <TextField
                label={'주소 입력*'}
                placeholder="주소 입력*"
                {...register('address', { required: '주소를 입력해주세요.' })}
                readOnly={true}
                helper={errors.address?.message}
                onClick={() => setOpenDaumPost(true)}
              />
            </div>
            <Button
              text="검색"
              className="filled-gray-900 flex-1 md:w-20 md:flex-auto"
              onClick={() => setOpenDaumPost(true)}
            />
          </div>
          <TextField
            placeholder="상세 주소 입력"
            peerStyle={false}
            helper={errors.addressDetail?.message}
            {...register('addressDetail', {
              required: '상세 주소를 입력해주세요.',
            })}
          />
        </div>

        <TextField
          label="공동현관 비밀번호"
          placeholder="공동현관 비밀번호"
          {...register('publicDoorPassword')}
        />
        <TextField
          label="배송 요청사항"
          placeholder="배송 요청사항"
          {...register('requestMemo')}
        />
      </div>
      <DaumPost
        address={getValues('address')}
        onComplete={onCompleteDaumPost}
        open={openDaumPost}
        onClose={() => setOpenDaumPost(false)}
      />
    </>
  );
};
