import { parse } from 'qs';
import React, { useMemo, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HorizontalScrollView } from '../../components/HorizontalScrollView';
import { Icon } from '../../components/Icon';
import { PromotionModal } from '../../components/modal/PromotionModal';
import { ProductCard } from '../../components/ProductCard';
import { Section } from '../../components/Section';
import { Tab } from '../../components/Tab';
import { TopBar } from '../../components/TopBar';
import { CartProvider } from '../../context/cart';
import { useCart } from '../../queries/cart';
import { useProductsByIds } from '../../queries/products';
import { Delivery } from './Delivery';
import { StorePickUp } from './StorePickUp';
import useAuth from '../../hooks/useAuth';

const CartPageBase = () => {
  const { authenticated } = useAuth();

  const params = parse(window.location.search.replace('?', ''));
  const [tab, setTab] = useState(params.tab === 'pickup' ? 1 : 0);
  const { online, pickup } = useCart();

  const productIdsRecentlyViewedStr = localStorage.getItem(
    'productIdsRecentlyViewed'
  );
  const productIdsRecentlyViewed = productIdsRecentlyViewedStr
    ? JSON.parse(productIdsRecentlyViewedStr)
    : [];
  const { products } = useProductsByIds(productIdsRecentlyViewed);
  const showRecentlyViewedPagination = useMemo(() => {
    if (window.innerWidth < 1280) {
      return productIdsRecentlyViewed.length >= 4;
    } else {
      return productIdsRecentlyViewed.length >= 6;
    }
  }, [productIdsRecentlyViewed.length]);

  return (
    <>
      <div className="mt-8 w-full max-w-screen-xl md:mx-auto">
        <TopBar title="장바구니" />

        {authenticated ? (
          <div className="flex w-full md:py-6">
            <Tab
              selected={tab === 0}
              onClick={() => setTab(0)}
              text={`일반배송(${online.count})`}
            />
            <Tab
              selected={tab === 1}
              onClick={() => setTab(1)}
              text={`북앤픽업(${pickup.count})`}
            />
          </div>
        ) : (
          <div className={`py-3 md:py-6`} />
        )}

        {tab === 0 && <Delivery />}

        {tab === 1 && <StorePickUp />}

        <div className="my-8 h-4 bg-gray-50" />

        <Section className="mx-5 space-y-4">
          <h5>
            <span className="pr-1 font-bold">최근 본 상품,</span>
            <span>함께 구매하시겠어요?</span>
          </h5>

          <Section className="relative w-full md:block">
            <Swiper
              navigation={{
                nextEl: '.swiper-button-next-0',
                prevEl: '.swiper-button-prev-0',
              }}
              loop={showRecentlyViewedPagination}
              modules={[Navigation]}
              className="mySwiper hidden md:block"
              spaceBetween={10}
              slidesPerView={3.5}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1280: {
                  slidesPerView: 6,
                  spaceBetween: 20,
                },
              }}
            >
              {products ? (
                <>
                  {products?.map((p) => (
                    <SwiperSlide key={p.id}>
                      <div className="">
                        <ProductCard
                          to={`/product/${p.id}`}
                          product={p}
                          showRanking={false}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <div className="mt-24 w-full text-center text-gray-500">
                    최근 본 상품이 없습니다.
                  </div>
                </>
              )}
            </Swiper>
            {showRecentlyViewedPagination && (
              <>
                <button className="swiper-button-prev-0 wh-10 absolute -left-10 top-36 hidden md:block">
                  <Icon.ChevronLeft />
                </button>
                <button className="swiper-button-next-0 wh-10 absolute -right-10 top-36 hidden place-content-center md:grid">
                  <Icon.ChevronRight />
                </button>
              </>
            )}
          </Section>

          <HorizontalScrollView className="snap-x snap-mandatory md:hidden">
            {products?.map((p) => (
              <div key={p.id} className="w-[28vw] snap-start">
                <ProductCard
                  to={`/product/${p.id}`}
                  product={p}
                  showRanking={false}
                />
              </div>
            ))}
          </HorizontalScrollView>
        </Section>
      </div>
    </>
  );
};

export const CartPage = () => {
  return (
    <CartProvider>
      <CartPageBase />
    </CartProvider>
  );
};
