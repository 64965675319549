import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as a } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { PhoneNumber } from '../../../components/PhoneNumber';
import { TextField } from '../../../components/TextField';
import { TopBar } from '../../../components/TopBar';
import CONST from '../../../const';
import { useAuth } from '../../../hooks';
import { useDeleteUser, useUpdateUser, useUser } from '../../../queries/user';
import { SignUpBody } from '../../../types/user';
import { modalState } from '../../../plugins/ridge';

export const EditMyPage = () => {
  const { signup } = useAuth();
  const { user } = useUser();
  const { updateUser } = useUpdateUser();
  const { deleteUser } = useDeleteUser();
  const {
    register,
    unregister,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<Partial<SignUpBody>>({ mode: 'onChange' });
  const [changePassword, setChangePassword] = useState(false);

  useEffect(() => {
    if (!user) return;
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('isMarketingUsageAgreed', !!user?.marketingUsageAgreedAt);
  }, [user]);

  const resetPassword = () => {
    setChangePassword(false);
    unregister('password');
    unregister('passwordConfirm');
  };

  const onEditPhoneNumber = (isEdit: boolean) => {
    if (isEdit) {
      register('phone', { required: true });
    } else {
      register('phone', { required: false });
    }
  };

  const onVerifiedPhoneNumber = (phone: string) => {
    setValue('phone', phone);
    setValue('phoneConfirmedAt', new Date().toISOString());
  };

  const onSubmit = (data: Partial<SignUpBody>) => {
    updateUser(data);
  };

  const onDelete = () => {
    modalState.set({
      title: '회원 탈퇴',
      bodyText:
        '탈퇴 후 재가입은 탈퇴일 기준 1년 경과 후 가능합니다.\n회원 탈퇴를 진행하시겠습니까?',
      primaryButtonText: '삭제',
      primaryClick: () => deleteUser(),
      secondaryButtonText: '취소',
    });
  };

  return (
    <div className="md:mx-auto md:w-full md:max-w-lg md:pt-14">
      <TopBar title="내 정보 수정" />

      <form
        className="flex flex-col space-y-9 px-5 py-6 md:px-0"
        onSubmit={handleSubmit((data) => signup(data))}
      >
        <TextField
          label="이름을 입력해주세요."
          placeholder="이름을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', { required: '이름을 입력해주세요.' })}
        />
        <TextField
          type="email"
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message}
          {...register('email', { required: '이메일을 입력해주세요.' })}
        />

        {changePassword ? (
          <>
            <TextField
              type="password"
              label="비밀번호를 입력해주세요."
              placeholder="비밀번호를 입력해주세요."
              helper={errors.password?.message}
              {...register('password', {
                required: '비밀번호를 입력해주세요',
                minLength: {
                  value: 8,
                  message: '비밀번호를 8자리 이상 입력해주세요.',
                },
              })}
            />

            <TextField
              type="password"
              label="비밀번호를 다시 입력해주세요."
              placeholder="비밀번호를 다시 입력해주세요."
              helper={errors.passwordConfirm?.message}
              {...register('passwordConfirm', {
                required: '비밀번호를 다시 입력해주세요',
                validate: (value) =>
                  value === getValues('password') ||
                  '비밀번호가 일치하지 않습니다.',
              })}
            />

            <Button
              text="비밀번호 변경 취소"
              className="outlined-gray-900"
              onClick={() => resetPassword()}
            />
          </>
        ) : (
          <Button
            text="비밀번호 변경"
            className="filled-gray-900"
            onClick={() => setChangePassword(true)}
          />
        )}

        <PhoneNumber
          initialValue={user.phone}
          onEdit={onEditPhoneNumber}
          onVerified={onVerifiedPhoneNumber}
          shouldCheckExist
        />

        <div className="space-y-4">
          <Checkbox
            {...register('isMarketingUsageAgreed')}
            label2={
              <a
                href={CONST.MARKETING_AGREEMENT_URL}
                target="_blank"
                rel="noreferrer"
                className="text-15 text-gray-600 underline"
              >
                마케팅 약관 동의(선택)
              </a>
            }
          />
        </div>

        <Button
          disabled={changePassword && !isValid}
          onClick={handleSubmit(onSubmit)}
          text="수정하기"
          className="filled-gray-900"
        />
      </form>
      <button
        onClick={onDelete}
        className="cursor-pointer text-13 text-gray-500 underline"
      >
        회원탈퇴
      </button>
    </div>
  );
};
