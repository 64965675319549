import React from 'react';
import { isMobile } from 'react-device-detect';
import { Section, sectionComponent } from '../../components/Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import { Navigation, Pagination } from 'swiper';
import { useMainBanners } from '../../queries/mainBanners';
import { useSections } from '../../queries/sections';
import { goToUrl } from './utils';
import { Icon } from '../../components/Icon';

export const HomePage = () => {
  const { mainBanners } = useMainBanners('MAIN');
  const { sections } = useSections('MAIN');

  return (
    <>
      <Section className="relative">
        {mainBanners.length > 0 && (
          <Swiper
            navigation={{
              nextEl: `.swiper-button-next-main`,
              prevEl: `.swiper-button-prev-main`,
            }}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            loop
            id="white_bullet"
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {mainBanners.map((banner) => (
              <SwiperSlide key={banner.id}>
                <div
                  onClick={() => goToUrl(banner.url)}
                  style={{
                    backgroundImage: `url(${
                      isMobile
                        ? banner.mobileBannerImageUrl
                        : banner.pcBannerImageUrl
                    })`,
                  }}
                  className={`relative aspect-[4/5]  cursor-pointer bg-cover bg-center bg-no-repeat md:aspect-[3/1]`}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <button
          className={`swiper-button-prev-main wh-10 absolute left-4 top-[50%] z-10 hidden md:block`}
        >
          <Icon.ChevronLeft className="text-gray-500" />
        </button>
        <button
          className={`swiper-button-next-main wh-10 absolute right-4 top-[50%] z-10 hidden place-content-center md:grid`}
        >
          <Icon.ChevronRight className="text-gray-500" />
        </button>
      </Section>

      {sections.map((section) => (
        <div
          key={section.id}
          className="w-full px-5 md:mx-auto md:max-w-screen-xl md:px-0"
        >
          {sectionComponent[section.type](section)}
        </div>
      ))}
    </>
  );
};
