import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../components/Icon';
import { ProductCard } from '../../components/ProductCard';
import { Button } from '../../components/Button';
import { Posting } from '../../types/posting';
import { useAddPostingProductsToCart } from '../../queries/cart';

interface TypeAListProps {
  posting: Posting;
  id: string;
}

export const TypeAList = ({ posting, id }: TypeAListProps) => {
  const { goBack } = useHistory();
  return (
    <>
      <div className="space-y-4 bg-brand-3 px-5 py-8 md:hidden">
        <button onClick={goBack} className="">
          <Icon.ChevronLeft />
        </button>
        <h4 className="font-semibold">{posting.title}</h4>
        <p
          className="text-15 text-gray-700"
          dangerouslySetInnerHTML={{ __html: posting.description }}
        />
      </div>

      <div className="">
        <img
          src={isMobile ? posting.mobileImageUrl : posting.pcImageUrl}
          className="h-full w-full bg-white "
          alt=""
        />
      </div>

      <div className="mt-8 grid grid-cols-2 gap-5 px-5 md:mx-auto md:max-w-screen-xl md:grid-cols-6">
        {posting.products.map(({ product }) => (
          <ProductCard
            key={product.id}
            to={`/product/${product.id}`}
            product={product}
            showRanking={false}
          />
        ))}
      </div>
    </>
  );
};
