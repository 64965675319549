import React from 'react';
import CONST from '../const';

export const Footer = () => {
  return (
    <div className="mx-auto w-full max-w-screen-xl px-4 pb-20 md:block md:pt-24">
      <div className="flex flex-col-reverse justify-between border-t border-gray-100 pt-8 text-gray-500 md:flex-row">
        <div className="mt-8 md:mt-0">
          <h6 className="mb-4 text-15 font-bold text-gray-500">아크앤북</h6>
          <p className="text-14">
            주식회사 오티디코퍼레이션 <br />
            대표이사 : 사공훈 <br />
            개인정보책임자 : 사공훈 <br />
            주소 : 서울시 용산구 대사관로34길 21, 5층 <br />
            고객문의 : 사이트 하단 '고객센터' <br />
            대관 및 협업 문의: anb.online@otdcorp.co.kr <br />
            사업자등록번호 : 104-86-56057 <br />
            통신판매업신고번호 : 제 2022-서울용산-0675 호 <br />
            <span className="text-10 md:text-14">
              Copyright © ARC·N·BOOK. All rights reserved.
            </span>
          </p>
        </div>

        <div className="flex h-full flex-col space-y-3 text-14 md:flex-row md:space-x-6 md:space-y-0">
          <button
            onClick={() => window.open('https://arcnbook.channel.io', '_blank')}
            type="button"
            className="text-left font-bold"
          >
            고객센터
          </button>

          {[
            {
              text: '이용약관',
              url: CONST.TERMS_AGREEMENT_URL,
            },
            {
              text: '개인정보 처리방침',
              url: CONST.PRIVACY_AGREEMENT_URL,
            },
            {
              text: '청소년 보호정책',
              url: CONST.YOUTH_SAFE_POLICY,
            },
          ].map((nav) => (
            <button
              key={nav.url}
              className="text-left"
              onClick={() => {
                window.open(nav.url, '_blank');
              }}
            >
              {nav.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
