import React, { FC, MouseEvent } from 'react';
import { Icon } from '../../components/Icon';
import { Section } from '../../components/Section';
import { ReactComponent as OrangeBookmark } from '../../assets/svg/orange-bookmark.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper';
import { Header } from './Header';
import { useHistory } from 'react-router-dom';
import { SectionDto } from '../../types/sections';
import { useAddProductToCart } from '../../queries/cart';
import { goToUrl } from './utils';
import { Product } from '../../types/products';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { useAddProductToNonCart } from '../../queries/nonMemberCarts';
import useAuth from '../../hooks/useAuth';

interface SingleCardSectionProps {
  section: SectionDto;
}

export const SingleCardSection = ({ section }: SingleCardSectionProps) => {
  const { push } = useHistory();

  return (
    <div className="mt-12 space-y-4">
      <Header
        title={section.title}
        description={section.description}
        onClick={() => goToUrl(section.clickUrl)}
      />

      <Section className="relative">
        {section.products.length > 0 && (
          <Swiper
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 3,
            }}
            navigation={{
              nextEl: `.swiper-button-next-${section.id}`,
              prevEl: `.swiper-button-prev-${section.id}`,
            }}
            loop
            id="gray_bullet"
            modules={[Pagination, Navigation]}
            className="mySwiper"
            spaceBetween={20}
            breakpoints={{
              420: {
                width: 420,
                slidesPerView: 1,
              },
              1280: {
                width: 1280,
                slidesPerView: 3,
              },
            }}
          >
            {section.products.map((p) => (
              <SwiperSlide key={p.id}>
                <div className="mb-10 ">
                  <ProductCard
                    onClick={() => push(`product/${p.product.id}`)}
                    product={p.product}
                    ranking={p.order}
                    description={p.description}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <button
          className={`swiper-button-prev-${section.id} wh-10 absolute -left-10 top-1/2 hidden md:block`}
        >
          <Icon.ChevronLeft />
        </button>
        <button
          className={`swiper-button-next-${section.id} wh-10 absolute -right-10 top-1/2 hidden place-content-center md:grid`}
        >
          <Icon.ChevronRight />
        </button>
      </Section>
    </div>
  );
};

interface ProductCardProps {
  product: Product;
  ranking: number;
  description: string;
  onClick: () => void;
}

const ProductCard: FC<ProductCardProps> = ({
  product,
  ranking,
  description,
  onClick,
}) => {
  const { authenticated } = useAuth();
  const { addProductToCart } = useAddProductToCart();
  const { addProductToNonCart } = useAddProductToNonCart();
  const onClickCart = (e: MouseEvent) => {
    e.stopPropagation();
    if (authenticated) {
      addProductToCart({ product });
      return;
    }
    addProductToNonCart({ product });
    return;
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="relative">
          <div
            onClick={onClick}
            className="relative z-10 mx-auto aspect-[3/4.5] w-[45vw] cursor-pointer overflow-hidden rounded-xl md:w-1/2"
          >
            <div className="absolute left-5 z-10">
              <span className="absolute top-0.5 left-[7px] text-white">
                {ranking}
              </span>
              <OrangeBookmark className="" />
            </div>

            <button className="wh-10 absolute bottom-3 right-3 z-10 grid place-content-center rounded-full bg-black bg-opacity-50">
              <Icon.ShoppingCart
                className="wh-5 text-white"
                onClick={(e) => onClickCart(e)}
              />
            </button>

            <img
              src={product.images[0]}
              alt=""
              className={`h-full w-full object-cover `}
            />
          </div>

          <div className="absolute bottom-0 h-10 w-full rounded-t-xl bg-brand-3" />
        </div>

        <div className="space-y-2 rounded-b-xl bg-brand-3 px-4 py-6">
          <h6 className="truncate font-semibold">{product.name}</h6>
          {product.bookInfo && (
            <p className="pb-2 text-12 text-gray-500">
              {product.bookInfo.authorName} | {product.bookInfo.publisherName} |
              출간일{' '}
              {utcToLocalFormat(
                product.bookInfo.publishedAt!,
                MomentFormat.Date
              )}
            </p>
          )}
          <p className="h-16 w-full overflow-hidden overflow-ellipsis text-14 text-gray-700">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};
